import React from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import '../../../App.css';
import { baseUrl } from '../../../api/auth_constant';
import colors from './colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const soilData = [
  {
    "commune": "Karimama",
    "sols": [
      "sols ferrugineux",
      "sols tropicaux à socle cristallin",
      "sols alluviaux très fertiles de la vallée du Niger",
      "sols argileux, limoneux noirs des bas-fonds et des marécages"
    ]
  },
  {
    "commune": "Malanville",
    "sols": [
      "sols ferrugineux",
      "sols tropicaux à socle cristallin",
      "sols alluviaux très fertiles de la vallée du Niger",
      "sols argileux, limoneux noirs des bas-fonds et des marécages"
    ]
  },
  {
    "commune": "Segbana",
    "sols": [
      "sols provenant du continental terminal (grès de Kandi)",
      "sols provenant du socle granito-gneissique"
    ]
  },
  {
    "commune": "Gogounou",
    "sols": [
      "sols provenant du continental terminal (grès de Kandi)",
      "sols provenant du socle granito-gneissique"
    ]
  },
  {
    "commune": "Kandi",
    "sols": [
      "sols relativement profonds",
      "sols peu concrétionnés",
      "sols parfois caillouteux",
      "sols argileux",
      "sols limoneux, noirs de bas-fonds marécageux"
    ]
  },
  {
    "commune": "Banikoara",
    "sols": [
      "sols relativement profonds",
      "sols peu concrétionnés",
      "sols parfois caillouteux",
      "sols argileux",
      "sols limoneux, noirs de bas-fonds marécageux"
    ]
  },
  {
    "commune": "Kérou",
    "sols": [
      "sols peu profonds très concrétionnés en surface",
      "sols hydromorphes profonds inondables et exposés à l'érosion"
    ]
  },
  {
    "commune": "N'Dali",
    "sols": [
      "sols ferrugineux tropicaux profonds non concrétionnés"
    ]
  },
  {
    "commune": "Nikki",
    "sols": [
      "sols ferralitiques"
    ]
  },
  {
    "commune": "Kalalé",
    "sols": [
      "sols sablo-argileux",
      "sols granito-argileux",
      "sols sableux"
    ]
  },
  {
    "commune": "Bembèrèkè",
    "sols": [
      "sols sablo-argileux",
      "sols granito-argileux"
    ]
  },
  {
    "commune": "Pehonko",
    "sols": [
      "sols hydromorphes",
      "sols ferrugineux tropicaux très concrétionnés"
    ]
  },
  {
    "commune": "PERERE",
    "sols": [
    "sols ferrugineux tropicaux",
    "sols très varié ferralitiques",
    "sols sablo-argileux ou argilo-sableux", 
    "sols granito-gneissique à caractère selon les toposéquences" 
    ]
  },
  {
    "commune": "Kouandé",
    "sols": [
      "sols hydromorphes",
      "sols ferrugineux tropicaux très concrétionnés"
    ]
  },
  {
    "commune": "Sinendé",
    "sols": [
      "sols ferralitiques"
    ]
  },
  {
    "commune": "Toucountouna",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "OUAKE",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Copargo",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Lalo",
    "sols": [
      "sols ferrugineux tropicaux",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Djakotomey",
    "sols": [
      "sols ferrugineux tropicaux",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Klouekanme",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Dogbo",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Houeyogbe",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Comé",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Toviklin",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
      "vertisols ou terres noires"
    ]
  },
  {
    "commune": "Agbangnizoun",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Bohicon",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Abomey",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Allada",
    "sols": [
      "sols rouges argileux",
    ]
  },
  {
    "commune": "Kpomasse",
    "sols": [
      "sols rouges argileux",
    ]
  },
  {
    "commune": "Porto-novo",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Adjarra",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Ifangni",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Cobli",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "AVRANKOU",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "AkPRO-MISSERETE",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Materi",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Boukombé",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Bonou",
    "sols": [
      "sols sableux et bas-fonds",
    ]
  },
  {
    "commune": "Dangbo",
    "sols": [
      "sols sableux et bas-fonds",
    ]
  },
  {
    "commune": "Aguegues",
    "sols": [
      "sols sableux et bas-fonds",
    ]
  },
  {
    "commune": "Seme-Kpodji",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Cotonou",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Adjahoun",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Ouidah",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Athiémé",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Lokossa",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Bopa",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Abomey-calavi",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "Grand-popo",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "So-ava",
    "sols": [
      "sols alluviaux et colluviaux",
    " sols sableux du cordon littoral"
   ]
  },
  {
    "commune": "ADJOHOUN",
    "sols": [
      "sols sableux et bas-fonds",
    ]
  },
  {
    "commune": "Bembéréké",
    "sols": [
      "sols sablo-argileux ",
      "granito-argileux",
      "sols sableux"
    ]
  },
  {
    "commune": "Natitingou",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Boukoumbè",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Matéri",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Djougou",
    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols hydromorphes"
    ]
  },
  {
    "commune": "Bassila",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Parakou",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Tchaourou",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Ouèsè",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Savalou",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "BANTE",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Dassa-Zoumé",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Glazoué",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "ZAGNANADO",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "ZA-KPOTA",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "OUINHI",
    "sols": [
      "sols de terre de barre ou sols ferralitiques",
    ]
  },
  {
    "commune": "Tanguièta",

    "sols": [
      "sols ferrugineux tropicaux peu profonds",
      "sols des bas-fonds ou sols hydromorphes"
    ]
  },
  {
    "commune": "Zè",

    "sols": [
      "sols de terre de barre ou sols ferralitiques ",
    ]
  },
  {
    "commune": "Sakété",

    "sols": [
      "sols de terre de barre ou sols ferralitiques ",
    ]
  },
  {
    "commune": "APLAHOUE",

    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Adja-ouèrè",

    "sols": [
      "sols ferrugineux tropicaux",
      "vertisols ou terres noires",
    ]
  },
  {
    "commune": "Kerou",

    "sols": [
      "sols peu profonds très concrétionnés en surface, pauvres faible capacité de réunion",
      "sols hydromorphes profonds inondables et exposés à l’érosion"
    ]
  },
  {
    "commune": "Savè",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Pobè",
    "sols": [
      "sols ferrugineux tropicaux",
      "les vertisols ou terres noires ",
    ]
  },
  {
    "commune": "TOFFO",
    "sols": [
      "sols ferrugineux tropicaux",
      "les vertisols ou terres noires ",
    ]
  },
  {
    "commune": "LALO",
    "sols": [
      "sols ferrugineux tropicaux",
      "les vertisols ou terres noires ",
    ]
  },
  {
    "commune": "Tori-bossito",
    "sols": [
      "sols rouges argileux",
      "sols rouges argileux-sableux",
      "sols sablo-argileux"
    ]
  },
  {
    "commune": "ZOGBODOMEY",
    "sols": [
      "sols ferrugineux tropicaux",
      "les vertisols ou terres noires ",
    ]
  },
  {
    "commune": "Kétou",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  },
  {
    "commune": "Djidja",
    "sols": [
      "sols ferralitiques",
      "sols ferrugineux",
      "sols noirs et hydromorphes des vallées"
    ]
  }
]

const cultureData = [
  {
    "commune": "Karimama",
    "cultures": [
      "Riz",
      "Mil",
      "Sorgho",
      "Maïs"
    ]
  },
  {
    "commune": "Malanville",
    "cultures": [
      "Riz",
      "Maïs",
      "Oignon",
      "Tomate"
    ]
  },
  {
    "commune": "Segbana",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide",
      "Niébé"
    ]
  },
  {
    "commune": "Gogounou",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Kandi",
    "cultures": [
      "Maïs",
      "Sorgho",
      "Cotton",
      "Riz"
    ]
  },
  {
    "commune": "Banikoara",
    "cultures": [
      "Cotton",
      "Maïs",
      "Sorgho",
      "Mil"
    ]
  },
  {
    "commune": "Kérou",
    "cultures": [
      "Mil",
      "Arachide",
      "Sorgho"
    ]
  },
  {
    "commune": "N'Dali",
    "cultures": [
      "Maïs",
      "Cotton",
      "Arachide"
    ]
  },
  {
    "commune": "Bante",
    "cultures": [
      "Maïs",
      "riz",
      "Arachide"
    ]
  },
  {
    "commune": "Savalou",
    "cultures": [
      "Maïs",
      "Manioc",
      "Arachide"
    ]
  },
  {
    "commune": "ouèsè",
    "cultures": [
      "Maïs",
      "riz",
      "Arachide"
    ]
  },
  {
    "commune": "Pèrèrè",
    "cultures": [
      "Maïs",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Nikki",
    "cultures": [
      "Maïs",
      "Sorgho",
      "Riz"
    ]
  },
  {
    "commune": "Kalalé",
    "cultures": [
      "Maïs",
      "Cotton",
      "Arachide"
    ]
  },
  {
    "commune": "Bembéréké",
    "cultures": [
      "Cotton",
      "Maïs",
      "Arachide"
    ]
  },
  {
    "commune": "Kétou",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Savè",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Glazoué",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Dassa-Zoumé",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Sakété",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Covè",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Ouinhi",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Djidja",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Aplahoue",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Za-kpota",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Abomey",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide",
      "Sorgho"
    ]
  },
  {
    "commune": "Tori-Bossito",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Zagnanado",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Adja-ouèrè",
    "cultures": [
      "Manioc",
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Pehonko",
    "cultures": [
      "Mil",
      "Arachide",
      "Maïs"
    ]
  },
  {
    "commune": "Kerou",
    "cultures": [
      "Mil",
      "Arachide",
      "Maïs"
    ]
  },
  {
    "commune": "PERERE",
    "cultures": [
      "Maïs",
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Kouandé",
    "cultures": [
      "Mil",
      "Sorgho",
      "Maïs"
    ]
  },
  {
    "commune": "Sinendé",
    "cultures": [
      "Maïs",
      "Cotton",
      "Arachide"
    ]
  },
  {
    "commune": "Toucountouna",
    "cultures": [
      "Mil",
      "Maïs",
      "Riz"
    ]
  },
  {
    "commune": "OUAKE",
    "cultures": [
      "Maïs",
      "Arachide",
      "Mil"
    ]
  },
  {
    "commune": "Copargo",
    "cultures": [
      "Mil",
      "Arachide",
      "Sorgho"
    ]
  },
  {
    "commune": "Djakotomey",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Klouekanme",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Dogbo",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Houeyogbe",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Comé",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Toviklin",
    "cultures": [
      "Maïs",
      "Riz",
      "Cotton"
    ]
  },
  {
    "commune": "Agbangnizoun",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Bohicon",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Allada",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Kpomasse",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Porto-novo",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Pobè",
    "cultures": [
      "Manioc",
      "Niébé",
      "cultures maraichères (tomate, piment)"
    ]
  },
  {
    "commune": "Toffo",
    "cultures": [
      "Manioc",
      "Niébé",
      "cultures maraichères (tomate, piment)"
    ]
  },
  {
    "commune": "Lalo",
    "cultures": [
      "Manioc",
      "Niébé",
      "cultures maraichères (tomate, piment)"
    ]
  },
  {
    "commune": "Adjarra",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Ifangni",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Tanguièta",
    "cultures": [
      "Mil",
      "Arachide",
      "Sorgho"
    ]
  },
  {
    "commune": "Cobli",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Tchaourou",
    "cultures": [
      "Coton",
      "Tabac",
      "Igname"
    ]
  },
  {
    "commune": "Parakou",
    "cultures": [
      "Coton",
      "Tabac",
      "Igname"
    ]
  },
  {
    "commune": "Bassila",
    "cultures": [
      "Coton",
      "Tabac",
      "Igname"
    ]
  },
  {
    "commune": "Djougou",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Zogbodomey",
    "cultures": [
      "Manioc",
      "Niébé",
      "cultures maraichères (tomate, piment)"
    ]
  },
  {
    "commune": "Avrankou",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Akpro-Misserete",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Materi",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Boukombé",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Natitingou",
    "cultures": [
      "Mil",
      "Sorgho",
      "Arachide"
    ]
  },
  {
    "commune": "Bonou",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Dangbo",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Aguegues",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Seme-Kpodji",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Cotonou",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Adjahoun",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Ouidah",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Athiémé",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Lokossa",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Bopa",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Abomey-calavi",
    "cultures": [
      "Maïs",
      "Riz",
      "Manioc"
    ]
  },
  {
    "commune": "Grand-popo",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "So-ava",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Adjohoun",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  },
  {
    "commune": "Zè",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Toffo",
    "cultures": [
      "Maïs",
      "Arachide",
      "Manioc"
    ]
  },
  {
    "commune": "Zangnanado",
    "cultures": [
      "Maïs",
      "Riz",
      "Arachide"
    ]
  }
];

const soilColorMapping = {
    "sols ferrugineux": "#F4A300", // Orange
    "sols ferrugineux tropicaux": "#F4A300", // Orange
    "sols ferrugineux tropicaux peu profonds": "#F4A300", 
    "sols tropicaux à socle cristallin": "#C4E0E5", // Light Blue
    "sols alluviaux très fertiles de la vallée du Niger": "#9B9B9B", // Gray
    "sols argileux, limoneux noirs des bas-fonds et des marécages": "#3E3A39", // Dark Gray
    "sols provenant du continental terminal (grès de Kandi)": "#D3B583", // Beige
    "sols provenant du socle granito-gneissique": "#6A5ACD", // Slate Blue
    "sols relativement profonds": "#8B4513", // Saddle Brown
    "sols peu concrétionnés": "#F5DEB3", // Wheat
    "sols parfois caillouteux": "#B22222", // Firebrick
    "sols argileux": "#BDB76B", // Dark Khaki
    "sols limoneux, noirs de bas-fonds marécageux": "#A9A9A9", // Dark Gray
    "sols peu profonds très concrétionnés en surface, pauvres faible capacité de réunion": "#FFD700", // Gold
    "sols hydromorphes profonds inondables et exposés à l'érosion": "#4682B4", // Steel Blue
    "sols ferrugineux tropicaux profonds non concrétionnés": "#CD5C5C", // Indian Red
    "sols ferralitiques": "#E0FFFF", // Light Cyan
    "sols sablo-argileux": "#F0E68C", // Khaki
    "sols granito-argileux": "#C0C0C0", // Silver
    "sols sableux": "#D2B48C", // Tan
    "sols sableux et bas-fonds": "#D2B48C", // Tan
    "sols hydromorphes": "#B0C4DE", // Light Steel Blue
    "sols ferrugineux tropicaux très concrétionnés": "#F5F5DC", // Beige
    "sols alluviaux et colluviaux": "#E6E6FA", // Lavender
    "sols sableux du cordon littoral": "#FAFAD2", // Light Goldenrod Yellow
    "sols rouges argileux": "#FF6347", // Tomato
    "sols rouges argileux-sableux": "#FF4500", // Orange Red
    "sols de terre de barre ou sols ferralitiques": "#FF69B4", // Hot Pink
    "sols noirs et hydromorphes des vallées": "#000000", // Black
  };

export default function MapChartSolCulture() {
  const [state, setState] = useState({ country: null, tractorsData: [], pannesData: [] });
  const [dialogData, setDialogData] = useState(null); // État pour gérer les données du dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false); // État pour gérer l'ouverture du dialog
  const svgRef = useRef(null);
  const height = 850;

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const jsonToData = async (data) => {
    try {
      const responses = await Promise.all([
        d3.json(String(data.countries)),
      ]);
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      setState({ country: countriesGeoJSON });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  
const getColorForSoil = (soils) => {
  for (let i = 0; i < soils.length; i++) {
    const color = soilColorMapping[soils[i]];
    if (color) {
      return color;
    }
  }
  return "#cccccc"; // Couleur par défaut si aucun sol trouvé
};
  useEffect(() => {
    if (state.country && svgRef.current) {
      const svg = d3.select(svgRef.current)
        .attr("viewBox", [0, 0, height / 2])
        .attr("height", height);

      const tooltip = d3.select("#tooltip").style('pointer-events', 'none').style("visibility", "hidden");

      const projection = d3.geoProjection(function (x, y) {
        return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
      }).scale(5800).translate([-20, 1370]);

      const path = d3.geoPath().projection(projection);

      svg.selectAll("*").remove();
      const g = svg.append("g")
        .attr("fill", "#444");

      g.selectAll("path")
        .data(state.country.features)
        .join('path')
        .attr('fill', function (d, i) {
            const soilDataForCurrentFeature = soilData.find(item => String(item.commune).toLowerCase() === d.properties.name.toLowerCase());

            if (soilDataForCurrentFeature && soilDataForCurrentFeature.sols.length > 0) {
              return getColorForSoil(soilDataForCurrentFeature.sols);
            }
            return "#cccccc"; // Couleur par défaut si aucun sol trouvé
          })

        .attr('d', path)
        .attr('title', (d) => d.properties?.name)
        .attr('stroke', 'gray')
        .attr('cursor', "pointer")
        .on("mouseover", (event, d) => {
          const soils = soilData.find(item => String(item.commune).toLowerCase() === d.properties.name.toLowerCase())?.sols.join(', ') || "Aucun";
          const cultures = cultureData.find(item => String(item.commune).toLowerCase() === d.properties.name.toLowerCase())?.cultures.join(', ') || "Aucune";

          tooltip.html(`
            <em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
            <em style="font-weight:bold;">Types de Sols : </em>${soils}<br/>
            <em style="font-weight:bold;">Culture : </em>${cultures}<br/>
          `).style('left', `${event.pageX / 2}px`).style('top', `${event.pageY / 2}px`).style("visibility", "visible");
        })
        .on("mouseout", (event, i) => {
          tooltip.html("").style("visibility", "hidden").style("top", 0);
        })
        .on("click", clicked);
      
   
    // Créer un groupe pour la légende à droite du SVG
    const legendGroup = svg.append("g")
      .attr("transform", `translate(${height / 2 + 50}, 50)`); // Positionner à droite du SVG

    // Ajouter chaque élément de la légende
    Object.entries(soilColorMapping).forEach(([soilType, color], i) => {
      const legendRow = legendGroup.append("g")
        .attr("transform", `translate(0, ${i * 25})`);

      legendRow.append("rect")
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", color)
        .attr("stroke", "black");

      legendRow.append("text")
        .attr("x", 30)
        .attr("y", 15)
        .style("font-size", "14px")
        .text(soilType);
    });

      // Fonction pour ouvrir le dialog avec les informations du tooltip
      function clicked(event, d) {
        const soils = soilData.find(item => String(item.commune).toLowerCase() === d.properties.name.toLowerCase())?.sols.join(', ') || "Aucun";
        const cultures = cultureData.find(item => String(item.commune).toLowerCase() === d.properties.name.toLowerCase())?.cultures.join(', ') || "Aucune";

        // Définir les données à afficher dans le dialog
        setDialogData({
          name: d.properties.name,
          soils: soils,
          cultures: cultures
        });
        setIsDialogOpen(true); // Ouvrir le dialog
      }
    }
  }, [state]);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  return (
    <>
      <svg width={"100%"} ref={svgRef} />
      <div id="tooltip"></div>
      {/* Dialog pour afficher les informations */}
      <BootstrapDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}   sx={{ 
    '& .MuiPaper-root': { 
      borderRadius: '40px' // Appliquer des bords arrondis au contenu du Dialog
    } 
  }}>
        {dialogData && (
          <>
            <DialogTitle sx={{fontWeight:'bold', color:'green'}}>{dialogData.name}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
          <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30, // diamètre du cercle
                height: 30, // diamètre du cercle
                borderRadius: '50%', // pour rendre la forme circulaire
                border: '2px solid red', // couleur et taille du bord du cercle
              }}
            >
              <CloseIcon sx={{ color: 'red' }} />
            </Box>
        </IconButton>
            <DialogContent dividers>
              <DialogContentText gutterBottom>
                <strong>Types de Sols :</strong> {dialogData.soils}<br />
                <strong>Culture :</strong> {dialogData.cultures}<br />
              </DialogContentText>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </>
  );
}
