import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Box, Paper, Autocomplete, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import CustomCard from '../CustomCard';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import { baseUrl } from '../../api/auth_constant';
import { commercialUrl } from '../../api/auth_constant';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';
import TypeChart from '../TypeChart';
import { MapChart } from './DashBoardCommercialUtils/MapChart';
import BarChartRender from './DashBoardCommercialUtils/BarChartRender';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LinkIcon from '@mui/icons-material/Link';
import { useMediaQuery } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import FlecheComponent from '../FlecheComponent';
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 

const TRACTEURS = [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
];
const REMORQUES = [
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088'
];
const KIT = [
    'KIT0012', 'KIT0011', 'KIT0008', 'KIT0007', 'KIT0003', 'KIT0005', 'KIT0009',
    'KIT0004', 'KIT0010', 'KIT0006', 'KIT0002', 'KIT0001', 'KIT0014'
];
const AUTRES = [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0079'
];
const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];
function DashboardCommercial() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [totalVentes, setTotalVentes] = useState(0);
    const [tracteursCount, setTracteursCount] = useState(0);
    const [remorquesCount, setRemorquesCount] = useState(0);
    const [ventePuissance, setVentePuissance] = useState([]);
    const [venteMarque, setVenteMarque] = useState([]);
    const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
    const [kitsCount, setKitsCount] = useState(0);
    const [autresCount, setAutres] = useState(0);
    const [typeChartData, setTypeChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [datas, setDatas] = useState({ totalClientsViile: [] });
    const [tabValue, setTabValue] = useState('1');

    useEffect(() => {
        fetchData(commercialUrl);
        getDatas();
    }, []);

    const getDatas = async () => {
        try {
            Promise.all([fetch(`${baseUrl}commercial/client_par_ville`)])
                .then(responses => Promise.all(responses.map(response => response.json())))
                .then(data => {
                    setDatas({
                        totalClientsViile: data[0]
                    });
                })
        } catch (error) {
            alert(error);
        }
    };

    const fetchData = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTotalVentes(data.length);

            let tracteursCount = 0;
            let remorquesCount = 0;
            let kitsCount = 0;
            let autresCount = 0;

            const countMap = {};
            const puissanceCountMap = {};
            const marqueCountMap = {};
            data.forEach(item => {
                const ref = item.AR_Ref;
                const design = item.DL_Design;

                if (TRACTEURS.includes(ref) || REMORQUES.includes(ref) || KIT.includes(ref)) {
                    countMap[design] = (countMap[design] || 0) + 1;
                }
            });
            data.forEach(sale => {
                const ref = sale.AR_Ref;
                if (sale.Puissance !== null) {
                  const puissance = sale.Puissance;
                  const marque = sale.Marque;
                  tracteursCount++;
                  marqueCountMap[marque] = (marqueCountMap[marque] || 0) + 1;
                  puissanceCountMap[puissance] = (puissanceCountMap[puissance] || 0) + 1;
                }
                 else if (REMORQUES.includes(ref)) {
                    remorquesCount++;
                } else if (KIT.includes(ref)) {
                    kitsCount++;
                } else if (AUTRES.includes(ref)) {
                    autresCount++;
                }
            });

            const formattedData = Object.entries(countMap).map(([label, value], index) => ({
                id: index,
                label: label,
                value: value,
            }));

            const ventPuissance = Object.entries(puissanceCountMap).map(([puissance, count]) => ({
              name: puissance, // Clé pour l'axe Y
              VenteParPuissance: count, // Clé pour les barres
          }));
          ventPuissance.sort((a, b) => b.VenteParPuissance - a.VenteParPuissance);

          const ventMarque = Object.entries(marqueCountMap).map(([marque, count]) => ({
            name: marque, // Clé pour l'axe Y
            VenteParMarque: count, // Clé pour les barres
        }));
        ventMarque.sort((a, b) => b.VenteParMarque - a.VenteParMarque);

        const totalTracteursParPuissance = Object.values(puissanceCountMap).reduce((total, count) => total + count, 0);
        const totalTracteursParMarque = Object.values(marqueCountMap).reduce((total, count) => total + count, 0);

        console.log('Total tracteurs par puissance:', totalTracteursParPuissance);
        console.log('Total tracteurs par marque:', totalTracteursParMarque);
        
            setTypeChartData(formattedData);
            setTracteursCount(tracteursCount);
            setRemorquesCount(remorquesCount);
            setVentePuissance(ventPuissance);
            setVenteMarque(ventMarque);
            setKitsCount(kitsCount);
            setAutres(autresCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleVagueChange = (event, newValue) => {
      setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
    };

    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const isXs = useMediaQuery('(max-width:600px)');

    return (
        <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Analyse des ventes', 'Analyse géographique'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
  
            <TabPanel value="1">

            <Box display="flex" alignItems="center" mb={2}>
                <Autocomplete
                  options={vagueOptions}
                  onChange={handleVagueChange}
                  renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
                  sx={{ width: 200, ml: 2 }} />
                
              </Box>
              {selectedVague === "" && (
                 <>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Tracteurs Vendus" value={`${tracteursCount}`} color="#ffff" icon={<AgricultureIcon />} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Remorques Vendues" value={`${remorquesCount}`} color="#ffff" icon={<RvHookupIcon />} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Kits Vendus" value={`${kitsCount}`} color="#ffff" icon={<DeveloperBoardIcon />} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Autres Equipements" value={`${autresCount}`} color="#ffff" icon={<LinkIcon />} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
              <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >   
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs vendus par puissance motrice</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' :'2.5rem', }}>({tracteursCount})</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >   
                  <div>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs vendus par marques</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' :'2.5rem', }}>({tracteursCount})</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: { xs: 400, md: 500 }, width: { xs: '100%', md: '100%' }, maxWidth: 1000, marginTop: 3 ,   padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}>
                    <BarChartRender datas={datas.totalClientsViile} />
                </Paper>
            </Grid>
            </>  )}

            {selectedVague === "Puissance motrice" && (
            <>
                  <div style={{ textAlign: 'center'}}>
                  <span style={{ fontWeight: 'bold', fontSize:isXs ? '1rem' : '1.5rem' }}>Données du mois de</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={16}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs vendus par puissance motrice</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' :'2.5rem', }}>({tracteursCount})</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                         <FlecheComponent currentMonthValue={tracteursCount} previousMonthValue={tracteursCount} />
                       </div>}
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={16} sx={{ display: 'flex', flexDirection: 'column', height: { xs: 400, md: 500 }, width: { xs: '100%', md: '100%' }, maxWidth: 1000, marginTop: 3 }}>
                    <BarChartRender datas={datas.totalClientsViile} />
                </Paper>
            </Grid>
                </>
            )}

            {selectedVague === "Marques" && (
            <>
                  <div style={{ textAlign: 'center'}}>
                  <span style={{ fontWeight: 'bold', fontSize:isXs ? '1rem' : '1.5rem' }}>Données du mois de</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={16}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs vendus par marques</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' :'2.5rem', }}>({tracteursCount})</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                         <FlecheComponent currentMonthValue={tracteursCount} previousMonthValue={tracteursCount} />
                       </div>}
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={16} sx={{ display: 'flex', flexDirection: 'column', height: { xs: 400, md: 500 }, width: { xs: '100%', md: '100%' }, maxWidth: 1000, marginTop: 3 }}>
                    <BarChartRender datas={datas.totalClientsViile} />
                </Paper>
            </Grid>
                </>
          )}
            </TabPanel>
  
            <TabPanel value="2">
            <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Vente par zone géographique
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChart />
                        </Paper>
                    </Grid>            
                </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  }
  
  export default RoleCheck(['Commercial'])(DashboardCommercial);
