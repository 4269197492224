import React, { useState, useEffect } from 'react';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, Typography, Grid, Box, Paper, Autocomplete, TextField, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import { Tab, Tabs, Nav, Row, Col, Container  } from 'react-bootstrap';
import CustomCard from './CustomCard';
import { statistiqueUrl, report, prodIndust } from '../api/auth_constant';
import CalendarButton from './CalendarButton';
import TypeChart from './TypeChart';
import ButtonComponent from './ButtonComponent';
import OperationAgricolComponent from './OperationAgricolComponent';
import { BarChart } from '@mui/x-charts/BarChart';
import { prodAgricol } from '../api/auth_constant';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MapChartCulture from './Dashboards/DashBoardSavUtils/MapChartCulture'
import MapChartRendement from './Dashboards/DashBoardSavUtils/MapChartRendement';
import MapChartTracteur from './Dashboards/DashBoardSavUtils/MapChartTracteur';
import MapChartSolCulture from './Dashboards/DashBoardSavUtils/MapChartSolCulture';

import iconSonama from '../static/images/logo_sonama.png';

const communeOptions = [
  { label: "ABOMEY" },
  { label: "ABOMEY-CALAVI" },
  { label: "ADJA-OUERE" },
  { label: "ADJARRA" },
  { label: "ADJOHOUN" },
  { label: "AGBANGNIZOUN" },
  { label: "AGUEGUES" },
  { label: "AKPRO-MISSERETE" },
  { label: "ALLADA" },
  { label: "APLAHOUE" },
  { label: "ATHIEME" },
  { label: "AVRANKOU" },
  { label: "BANIKOARA" },
  { label: "BANTE" },
  { label: "BASSILA" },
  { label: "BEMBEREKE" },
  { label: "BOHICON" },
  { label: "BONOU" },
  { label: "BOPA" },
  { label: "BOUKOUMBE" },
  { label: "COBLY" },
  { label: "COME" },
  { label: "COPARGO" },
  { label: "COTONOU" },
  { label: "COVE" },
  { label: "DANGBO" },
  { label: "DASSA-ZOUME" },
  { label: "DJAKOTOMEY" },
  { label: "DJIDJA" },
  { label: "DJOUGOU" },
  { label: "DOGBO" },
  { label: "GLAZOUE" },
  { label: "GOGOUNOU" },
  { label: "GRAND-POPO" },
  { label: "HOUEYOGBE" },
  { label: "IFANGNI" },
  { label: "KALALE" },
  { label: "KANDI" },
  { label: "KARIMAMA" },
  { label: "KEROU" },
  { label: "KETOU" },
  { label: "KLOUEKANME" },
  { label: "KOUANDE" },
  { label: "KPOMASSE" },
  { label: "LALO" },
  { label: "LOKOSSA" },
  { label: "LOKOSSA " },
  { label: "MALANVILLE" },
  { label: "MATERI" },
  { label: "N'DALI" },
  { label: "NATITINGOU" },
  { label: "NIKKI" },
  { label: "OUAKE" },
  { label: "OUESSE" },
  { label: "OUIDAH" },
  { label: "OUINHI" },
  { label: "PARAKOU" },
  { label: "PEHOUNCO" },
  { label: "PERERE" },
  { label: "POBE" },
  { label: "PORTO-NOVO" },
  { label: "SAKETE" },
  { label: "SAVALOU" },
  { label: "SAVE" },
  { label: "SEGBANA" },
  { label: "SEME-PODJI" },
  { label: "SINENDE" },
  { label: "SO-AVA" },
  { label: "TANGUIETA" },
  { label: "TCHAOUROU" },
  { label: "TOFFO" },
  { label: "TORI-BOSSITO" },
  { label: "TOUCOUNTOUNA" },
  { label: "TOVIKLIN" },
  { label: "ZA-KPOTA" },
  { label: "ZAGNANADO" },
  { label: "ZE" },
  { label: "ZOGBODOMEY" },
];

const cultureOptions = [
  { label: "Anacarde" },
  { label: "Ananas" },
  { label: "Arachide" },
  { label: "Carotte" },
  { label: "Choux" },
  { label: "Citulus" },
  { label: "Concombre" },
  { label: "Coton" },
  { label: "CrinCrin" },
  { label: "Fonio" },
  { label: "Gboma" },
  { label: "Gombo" },
  { label: "Goussi" },
  { label: "Haricot Vert" },
  { label: "Igname" },
  { label: "Laitue" },
  { label: "Manioc" },
  { label: "Mais" },
  { label: "Niébé" },
  { label: "Oignon" },
  { label: "Pastèque" },
  { label: "Patate Douce" },
  { label: "Petit mil" },
  { label: "Piment" },
  { label: "Poids d'angole" },
  { label: "Pomme de terre" },
  { label: "Riz" },
  { label: "Soja" },
  { label: "Sorgho" },
  { label: "Sésame" },
  { label: "Taro" },
  { label: "Tomate" },
  { label: "Voandzou" },
];



const ProductionAgricoleComponent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [Total, setTotal] = useState(0);
  const [Distance, setDistance] = useState(0);
  const [Heure_moteur, setHeure_moteur] = useState(0);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCommune, setSelectedCommune] = useState(null);
  const [selectedCulture, setSelectedCulture] = useState(null);
  const [agricultureData, setAgricultureData] = useState(null);
  const [period, setPeriod] = useState([]);
  const [AgriData, setAgriData] = useState([]);
  const [hoursByDesignation, setHoursByDesignation] = useState({});
  const [year, setYear] = useState('');


  useEffect(() => {
    fetchData(statistiqueUrl);
    fetch2Data();
  }, []);

  const isValidNumber = (value) => {
    // Vérifie si la valeur est un nombre valide
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

const filterByYear = (data) => {
    // Crée un ensemble des années uniques à partir des données
    const years = [...new Set(data.map(item => item["Année"] || "Non défini"))];

    // Initialise les objets de comptage pour chaque année
    const yearCounts = years.reduce((acc, year) => {
        acc[year] = { Production: 0, Superficie: 0, Rendement: 0 };
        return acc;
    }, {});

    // Accumule les valeurs pour chaque année
    data.forEach(item => {
        const year = item["Année"];
        if (year) {
            // Vérifie et accumule la production
            const production = isValidNumber(item.Production) ? parseFloat(item.Production) : 0;
            yearCounts[year].Production += production;

            // Vérifie et accumule la superficie
            const superficie = isValidNumber(item.Superficie) ? parseFloat(item.Superficie) : 0;
            yearCounts[year].Superficie += superficie;

            // Vérifie et accumule le rendement
            const rendement = isValidNumber(item.Rendement) ? parseFloat(item.Rendement) : 0;
            yearCounts[year].Rendement += rendement;
        }
    });

    return yearCounts;
};

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTotal(data["Total"]);
      setDistance(data["Distance"]);
      setHeure_moteur(data["Heure_moteur"]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      if (selectedCommune && selectedCulture) {
        fetchAgricultureData(selectedCommune, selectedCulture);
      }
    }, [selectedCommune, selectedCulture]);
  
    const fetchAgricultureData = async () => {
      try {
        const requestBody = {
          culture: selectedCulture,
          commune: selectedCommune
        };
  
        const response = await fetch(prodAgricol, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const jsondata = await response.json();
        const periods = [...new Set(jsondata.map(item => item['Année']))].filter(Boolean);
        setPeriod(periods);
        const filteredData = filterByYear(jsondata)
        const chartData = {
          Production: periods.map(period => filteredData[period]?.Production || 0),
          Superficie: periods.map(period => filteredData[period]?.Superficie || 0),
          Rendement: periods.map(period => filteredData[period]?.Rendement || 0),
        };
        setAgricultureData(chartData);
      } catch (error) {
        console.error('Error fetching agriculture data:', error);
      }
    };

    const fetch2Data = async () => {
      try {
        const response = await fetch(report);
        if (!response.ok) throw new Error('Network response was not ok');
        const reportsData = await response.json();
  
        const productionResponse = await fetch(prodIndust);
        if (!productionResponse.ok) throw new Error('Network response was not ok');
        const productionData = await productionResponse.json();
  
        const categories = {
          'TRACTEURS': ['PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'],
          'REMORQUES': ['PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088'],
          'AUTRES': ['PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102']
        };
  
        // Get available years from the dataset
        const results = productionData.reduce((acc, prodItem) => {
          const reportItem = reportsData.find(rep => rep.tractor === prodItem['Numéro chassis']);
    
          // Ajout du filtre d'année
          const productionYear = new Date(prodItem['Date']).getFullYear();
          if (reportItem && (year === "" || productionYear === parseInt(year))) { // Filtre par année
            const refArticle = prodItem['Réf Article'];
            const designationArticle = prodItem['Désignation Article'];
            
            const category = Object.keys(categories).find(key => categories[key].includes(refArticle));
            if (category === 'TRACTEURS') {
              if (!acc[designationArticle]) {
                acc[designationArticle] = { distance: 0, hours: 0 };
              }
              acc[designationArticle].distance += parseFloat(reportItem['Distance'] || 0);
              acc[designationArticle].hours += parseFloat(reportItem['Heure_moteur'] || 0);
            }
          }
          return acc;
        }, {});
    
        const chartData = Object.keys(results).map(key => ({
          label: key,
          distance: results[key].distance,
          hours: results[key].hours
        }));
    
        setAgriData(chartData);  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    const handleYearChange = (event) => {
      setYear(event.target.value);
      fetch2Data(); // Refetch data after selecting year
    };  
    
  
    const handleDrawerOpen = () => {
      setSidebarOpen(true);
    };
  
    const handleDrawerClose = () => {
      setSidebarOpen(false);
    };
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    const handleCommuneChange = (event, value) => {
      setSelectedCommune(value?.label || null);
    };
  
    const handleCultureChange = (event, value) => {
      setSelectedCulture(value?.label || null);
    };

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
    const shouldDisplayMessage = !selectedCommune || !selectedCulture;

    return (
      <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={handleDrawerClose} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
            <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
              <CalendarButton selectedDate={selectedDate} handleDateChange={handleDateChange} />
              <Box ml={2}>
                <ButtonComponent barChartData={data} lineChartData={data2} />
              </Box>
            </Box>
            <TabContext value={tabValue}>
              <Box width="100%" 
                mx="auto" 
                sx={{ 
                  borderBottom: { xs: 'none', sm: 3 }, 
                  borderColor:  { xs: 'transparent', sm: 'divider', md: 'divider' },
                  overflowX: 'auto',
                }}>
              <Nav 
                variant="tabs" 
                defaultActiveKey="1" 
                className="flex-column flex-sm-row justify-content-start"
              >
                {[
                'Production Agricole',
                'Operation Agricole',
                'Analyse géographique',
                'Sols et tracteurs',
              ].map((label, index) => (
                <Nav.Item key={index} className="flex-fill"> 
                  <Nav.Link
                    eventKey={`${index + 1}`}
                    active={tabValue === `${index + 1}`}
                    onClick={() => handleTabChange(null, `${index + 1}`)}
                    style={{
                      color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                      fontSize: '1.2rem',
                      textTransform: 'none',
                      whiteSpace: 'nowrap', // Empêche le retour à la ligne
                    }}
                  >
                    {label}
                  </Nav.Link>
                </Nav.Item>
              ))}
              </Nav>
            </Box>
            
          <TabPanel value="1">
            {/* <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <CustomCard title="Distance Parcourue" value={`${Distance} Km`} color="#ffff" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomCard title="Nombre de tracteurs" value={`${Total}`} color="#ffff" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomCard title="Heures Moteur" value={`${Heure_moteur} km/h`} color="#ffff" />
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={4} className="d-flex flex-column align-items-center">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Année</InputLabel>
              <Select value={year} onChange={handleYearChange} label="Année">
                <MenuItem value="">
                  <em>Toutes les années</em>
                </MenuItem>
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2022">2022</MenuItem>
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
              </Select>
            </FormControl>

            </Grid>

            {/* Chart Section */}
            <Grid item xs={12} md={4} className="d-flex flex-column align-items-center">
              <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Distance parcourue & Heures moteur 
              </Typography>
              <Paper
                elevation={16}
                sx={{
                  padding: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 400,
                  height: 400,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
                  backgroundColor: 'white',
                }}
              >
                <TypeChart data={AgriData} />
              </Paper>
            </Grid>
    

            <Grid container display={"flex"} alignItems={'center'} spacing={1} mt={3}>
              <Grid item xs={12} md={8}>
                <Box display="flex" alignItems="center">
                  <Autocomplete
                    options={communeOptions}
                    onChange={handleCommuneChange}
                    renderInput={(params) => <TextField {...params} label="Commune"/>}
                    sx={{ width: 200 }}
                  />
                  <Autocomplete
                    options={cultureOptions}
                    onChange={handleCultureChange}
                    renderInput={(params) => <TextField {...params} label="Culture" />}
                    sx={{ width: 200, marginLeft: '50px' }}
                  />
                </Box>
              </Grid>
            </Grid>
                <Paper elevation={2} sx={{marginTop:8, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 500 }}>
                  {shouldDisplayMessage ? (
                    <Typography variant="h6" sx={{  textAlign:'center' }}>
                      Veuillez choisir une commune et une culture 
                    </Typography>
                  ) : (
                    agricultureData && (
                      <BarChart
                      series={[{
                        data: agricultureData.Production,
                        label: 'Production en tonnes',
                        id: 'prod'
                      }]}                      
                       xAxis={[{ scaleType: 'band', data: period, colorMap: { type: 'ordinal', colors: ['#4eb3d3', '#08589e', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'] } }]}
                        margin={{ left: 80, right: 80, top: 80, bottom: 80 }}
                       
                      />
                    )
                  )}
                </Paper>
                <Paper elevation={2} sx={{marginTop:5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 500 }}>
                  {shouldDisplayMessage ? (
                    <Typography variant="h6" sx={{textAlign:'center' }}>
                      Veuillez choisir une commune et une culture 
                    </Typography>
                  ) : (
                    agricultureData && (
                      <BarChart
                      series={[{
                        data: agricultureData.Superficie,
                        label: 'Superficie en ha',
                        id: 'sup'
                      }]}                     
                       xAxis={[{ scaleType: 'band', data: period, colorMap: { type: 'ordinal', colors: ['#fce556', '#800080', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'] } }]}
                        margin={{ left: 80, right: 80, top: 80, bottom: 80 }}
                      />
                    )
                  )}
                </Paper>
                <Paper elevation={2} sx={{marginTop:5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 500 }}>
                  {shouldDisplayMessage ? (
                    <Typography variant="h6" sx={{ textAlign:'center' }}>
                      Veuillez choisir une commune et une culture 
                    </Typography>
                  ) : (
                    agricultureData && (
                      <BarChart
                      series={[{
                        data: agricultureData.Rendement,
                        label: 'Rendement en kg/ha',
                        id: 'rend'
                      }]}                     
                       xAxis={[{ scaleType: 'band', data:period, colorMap: { type: 'ordinal', colors: ['#964B00', '#dda827', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'] } }]}
                        margin={{ left: 80, right: 80, top: 80, bottom: 80 }}
                      />
                    )
                  )}
                </Paper>
          </TabPanel>
          <TabPanel value="2">
            <OperationAgricolComponent/>
          </TabPanel>
          <TabPanel value="3">
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
          <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Production par ville</Typography>
                  <Paper elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 400,
                    position: 'relative'
                  }}>
                    <MapChartCulture/>
                  </Paper>
                  </Grid>
                  <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Rendement par ville</Typography>
                  <Paper elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 400,
                    position: 'relative'
                  }}>
                    <MapChartRendement/>
                  </Paper>
                  </Grid>
                  </Grid>
                   </TabPanel>
                   <TabPanel value="4">
                   <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Nombre de tracteur et pannes</Typography>
                  <Paper square={false}  elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 460,
                    position: 'relative',
                  }}>
                    <MapChartTracteur/>
                  </Paper>
                  <Typography mt={3} sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Sols et cultures</Typography>
                  <Paper square={false}  elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 'auto',
                    position: 'relative',
                  }}>
                    <MapChartSolCulture/>
                  </Paper>
                  </Grid>
                  </TabPanel>
        </TabContext>
        </Box>
      </Box>
    );
  };
  
  export default ProductionAgricoleComponent;
  