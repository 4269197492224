import React, { useState, useEffect } from 'react';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { AppBar, Toolbar, Typography, Grid, Box, Select, MenuItem, FormControl, InputLabel, Paper  } from '@mui/material';
import { Tab, Tabs, Nav, Row, Col, Container  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importer Bootstrap CSS
import CustomCard from '../CustomCard';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';
import TableComponent from '../TableComponent';
import TypeChart from '../TypeChart';
import MapChart2 from "./DashBoardMecanicienUtils/MapChart";
import VisiteComponent from '../VisiteComponent';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { panne, stastiquePanne } from '../../api/auth_constant';
import BuildIcon from '@mui/icons-material/Build';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BusAlertIcon from '@mui/icons-material/BusAlert';

function DashboardMecanicien() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [totalPannes, setTotalPannes] = useState(0);
  const [tempsResolution, setTempsResolution] = useState('0');
  const [nombreClotures, setNombreClotures] = useState(0);
  const [nombreNonClotures, setNombreNonClotures] = useState(0);
  const [data, setData] = useState(null);
  const [pannesData, setPannesData] = useState([]);
  const [typeChartData, setTypeChartData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tabValue, setTabValue] = useState('1');

  useEffect(() => {
    fetchData();
    fetchTypeChartData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(panne);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setPannesData(data);
      
      // Total de pannes
      const total = data.length;
      setTotalPannes(total);
      
      // Temps moyen de résolution
      const totalTempsResolution = data.reduce((acc, panne) => {
        if (panne.start && panne.end) {
          const start = new Date(panne.start);
          const end = new Date(panne.end);
          return acc + (end - start);
        }
        return acc;
      }, 0);
      const averageTempsResolution = totalTempsResolution / total;
      const hours = Math.floor(averageTempsResolution / (1000 * 60 * 60));
      const minutes = Math.floor((averageTempsResolution % (1000 * 60 * 60)) / (1000 * 60));
      setTempsResolution(`${hours} h`);

      // Pannes résolues et non résolues
      const clotures = data.filter(panne => panne['Dossier clôturé'].toLowerCase() === 'oui').length;
      const nonClotures = data.filter(panne => panne['Dossier clôturé'].toLowerCase() === 'non' || panne['Dossier clôturé'] === '').length;
      setNombreClotures(clotures);
      setNombreNonClotures(nonClotures);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchTypeChartData = async () => {
    try {
      const response = await fetch(stastiquePanne);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      const combinedData = data.pannes_par_marque;
      const formattedData = Object.entries(combinedData).map(([label, value], index) => ({
        id: index,
        label: label,
        value: value,
      }));
      setTypeChartData(formattedData);

    } catch (error) {
      console.error('Error fetching type chart data:', error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = [
    { id: 'Client', label: 'Client', align: 'right', flex: 1 },
    { id: 'Date', label: 'Date', align: 'right', flex: 1 },
    { id: 'Cause', label: 'Cause', align: 'left', flex: 2 }, 
    { id: 'Description', label: 'Description', align: 'right', flex: 3 },
    { id: 'Dossier clôturé', label: 'Dossier clôturé', align: 'right', flex: 1 },
    { id: 'Intervenant', label: 'Intervenant', align: 'right', flex: 1 },
    { id: 'Machine en panne', label: 'Machine en panne', align: 'right', flex: 1 },
    { id: 'Numéro de châssis', label: 'Numéro de châssis', align: 'right', flex: 1 },
    { id: 'Genre de visite', label: 'Genre de visite', align: 'right', flex: 1 },
  ];
  

  const isMobile = window.innerWidth < 600;

  return (
    <Box sx={{ display: 'flex' }}>
     <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
        <TabContext value={tabValue}>
        <Box 
      width="100%" 
      mx="auto" 
      sx={{ 
        borderBottom: { xs: 'none', sm: 3 }, 
        borderColor:  { xs: 'transparent', sm: 'divider', md: 'divider' },
        overflowX: 'auto', // Ajout pour gérer le débordement horizontal
      }}
    >
      <Nav 
        variant="tabs" 
        defaultActiveKey="1" 
        className="flex-column flex-sm-row justify-content-start" // Suppression de w-50
      >
        {['Analyse des pannes', 'Suivi équipement', 'Analyse géographique'].map((label, index) => (
          <Nav.Item key={index} className="flex-fill"> 
            <Nav.Link
              eventKey={`${index + 1}`}
              active={tabValue === `${index + 1}`}
              onClick={() => handleTabChange(null, `${index + 1}`)}
              style={{
                color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                fontSize: '1.2rem',
                textTransform: 'none',
                width: '100%', // Maintenir 100% de largeur
                whiteSpace: 'nowrap', // Empêche le retour à la ligne
              }}
            >
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Box>
  
          {/* Tab panels remain the same */}
          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Pannes Signalées" value={`${totalPannes}`} color="#ffff" icon={<BuildIcon />} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Temps résolution" value={`${tempsResolution}`} color="#ffff" icon={<AlarmOnIcon />} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Pannes Résolues" value={`${nombreClotures}`} color="#ffff" icon={<DoneAllIcon />} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Pannes Irrésolues" value={`${nombreNonClotures}`} color="#ffff" icon={<BusAlertIcon />} />
              </Grid>
  
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={6} className="d-flex flex-column align-items-center">
                  <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}>
                    Pannes par puissance motrice
                  </Typography>
                  <Paper 
                    elevation={16} 
                    sx={{ 
                      padding: 1, 
                      width: { xs: '100%', sm: '90%', md: '100%' },
                      maxWidth: { xs: 300, sm: 500, md: 400 },
                      height: { xs: 250, sm: 300, md: 400 },
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center',
                  }}
                  >
                    <TypeChart data={typeChartData} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
  
            <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', // Prend 100% de la largeur
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '100%',   // 100% pour sm et au-delà
                  },
                }}
              >
                <TableComponent
                  data={pannesData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>


          </TabPanel>
          
          <TabPanel value="2">
            <VisiteComponent />
          </TabPanel>
          
          <TabPanel value="3">
          <Grid item xs={12} md={4} mt={3}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold'}}>
                    Mécaniciens par ville 
                  </Typography>
                  <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 400, position: 'relative' }}>
                    <MapChart2 />
                  </Paper>
                </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
  

};

export default RoleCheck(['Mecanicien'])(DashboardMecanicien);