import React, { useState, useEffect } from 'react';
import RoleCheck from '../Components/Dashboards/RoleCheck';
import { AppBar, Toolbar, Box, Container, Grid, Paper, Typography,Autocomplete, TextField } from '@mui/material';
import CustomCard from './CustomCard';
import { formation_irrigations} from '../api/auth_constant';
import BarChar from './BarChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import PersonIcon from '@mui/icons-material/Person';
import Face4Icon from '@mui/icons-material/Face4';
import Face5Icon from '@mui/icons-material/Face5';
import { useMediaQuery } from '@mui/material';

const anneeOptions = [
    { label: "2022" },
    { label: "2023" },

  ];
function FormationIrrigation() {
  const [data, setData] = useState(null);
  const [card, setCard] = useState(null);
  const [formationData, setFormation] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [totalTractoristes, setTotalTractoristes] = useState(0);
  const [tractoristesParSexe, setTractoristesParSexe] = useState({ masculin: 0, feminin: 0 });
  const [totalTractoristesCard, setTractoristes] = useState(0);
  const [tractoristesParSexeCard, setParSexe] = useState({ masculin: 0, feminin: 0 });
  const [tabValue, setTabValue] = useState('1');
  const [selectedVague, setSelectedVague] = useState(null);
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const [vagueOptions, setVagueOptions] = useState([]);
  const [period, setPeriod] = useState([]);
  const [chartData, setChartData] = useState({
    labels: period,
    masculins: new Array(period.length).fill(0),
    feminin: new Array(period.length).fill(0),
  });
  const [PieData, setPieData] = useState([]);
  const [niveauPieData, setniveauPieData] = useState([]);
  const [ageData, setAgeData] = useState({
    labels: [],
    values: []
  });
  
  useEffect(() => {
    fetchTractoristesData(formation_irrigations);
    fetchTractoristesCard(formation_irrigations)
  }, [selectedVague, selectedAnnee]);

  const filterBySite = (data) => {
    const sites = [...new Set(data.map(item => item["Site de formation "] || "Non défini"))]; // Récupérer tous les sites uniques
    const siteCounts = sites.reduce((acc, site) => {
      acc[site] = { masculin: 0, feminin: 0 };
      return acc;
    }, {});
  
    data.forEach(item => {
      const site = item["Site de formation "];
      if (site) {
        if (item.Sex === 'M') siteCounts[site].masculin++;
        else if (item.Sex === 'F') siteCounts[site].feminin++;
      }
    });
  
    return siteCounts;
  };
  const filterByAge = (data) => {
    const ageGroups = {
      '0-25': 0,
      '25-50': 0,
      '>50': 0,
      'N/A': 0
    };
  
    data.forEach(item => {
      const age = item["Age "]; 
      if (age === undefined || age === "") {
        ageGroups['N/A']++;
      } else {
        if (age <= 25) ageGroups['0-25']++;
        else if (age <= 50) ageGroups['25-50']++;
        else if (age > 50) ageGroups['>50']++;
      }
    });    
  
    return ageGroups;
  };
  const fetchTractoristesData = async () => {
    try {
      const response = await fetch(formation_irrigations, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Vague: selectedVague,
            Année: selectedAnnee,
        }),
    });
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
    }
      const jsonData = await response.json();
      console.log(jsonData);
      
      const uniqueVagues = [...new Set(jsonData.map(item => item['Vague']))].filter(Boolean);
      setVagueOptions(uniqueVagues.map(label => ({ label })));
        const periods = [...new Set(jsonData.map(item => item['Site de formation ']))].filter(Boolean);

      // Calculer les statistiques
      const totalTractoristes = jsonData.length;
      const tractoristesParSexe = jsonData.reduce((acc, curr) => {
        const site = curr["Site de formation "];
        if (curr.Sex === 'M') {
          acc.masculin++;
        } else if (curr.Sex === 'F') {
          acc.feminin++;
        }
        return acc;
      }, { masculin: 0, feminin: 0 });
       const periodCounts = filterBySite(jsonData);
       console.log('Period Counts:', periodCounts); // Vérifiez le résultat de filterByPeriod
      // Mettre à jour les états
      setTotalTractoristes(totalTractoristes);
      setTractoristesParSexe(tractoristesParSexe);
      const chartData = {
        labels: periods,
        masculins: periods.map(period => periodCounts[period]?.masculin || 0),
        feminin: periods.map(period => periodCounts[period]?.feminin || 0),
      };
      setChartData(chartData);
      const siteFormationData = jsonData.reduce((acc, curr) => {
        const site = curr["Site de formation "];
        if (!acc[site]) {
          acc[site] = { total: 0, masculin: 0, feminin: 0 };
        }
        acc[site].total++;
        return acc;
      }, {});
        // Conversion des données en format pour le pie chart
        const pieChartData = Object.keys(siteFormationData).map(site => ({
          name: site,
          total: siteFormationData[site].total,
        }));
        setPieData(pieChartData);
        const niveauFormationData = jsonData.reduce((acc, curr) => {
          const niveau = curr["Niveau d'étude "];
        if (!acc[niveau]) {
          acc[niveau] = { total: 0, masculin: 0, feminin: 0 };
        }
        acc[niveau].total++;
        return acc;
      }, {});
          // Conversion des données en format pour le pie chart
          const niveauChartData = Object.keys(niveauFormationData).map(niveau => ({
            name: niveau,
            total: niveauFormationData[niveau].total,
          }));
          const filteredData = niveauChartData.filter(data => data.name.trim() !== "" && !["/", " "].includes(data.name));
          setniveauPieData(filteredData);
          const ageStats = filterByAge(jsonData);
          setAgeData({
            labels: ['0-25','25-50','>50','Non renseignés'],
            values: Object.values(ageStats),
          });
    } catch (error) {
      console.error('Erreur:', error);
    }
  };
  
  const fetchTractoristesCard= async () => {
    try {
      const response = await fetch(formation_irrigations, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Vague: "",
            Année: "",
        }),
    });
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
    }
      const jsonData = await response.json();
      setCard(jsonData);   
      const totalTractoristesCard = jsonData.length;
      const tractoristesParSexeCard = jsonData.reduce((acc, curr) => {
        if (curr.Sex === 'M') {
          acc.masculin++;
        } else if (curr.Sex === 'F') {
          acc.feminin++;
        }
        return acc;
      }, { masculin: 0, feminin: 0 });
  
      // Mettre à jour les états
      setTractoristes(totalTractoristesCard);
      setParSexe(tractoristesParSexeCard); 
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const handleVagueChange = (event, value) => {
    setSelectedVague(value?.label || null);
  };
  
  const handleAnneeChange = (event, value) => {
    setSelectedAnnee(value?.label || null);
  };
  const isXs = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1}}>
          <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <CustomCard title="Personnes formées" value={totalTractoristesCard} color="#ffff" icon={<PersonIcon />}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomCard title="Irrigateurs formés" value={tractoristesParSexeCard.masculin} color="#ffff" icon={<Face5Icon />}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomCard title="Irrigatrices formées" value={tractoristesParSexeCard.feminin} color="#ffff" icon={<Face4Icon />}/>
              </Grid>
            </Grid>  
            <Grid container display={"flex"} alignItems={'center'} spacing={1} mt={3}>
              <Grid item xs={12} md={8}>
                <Box display="flex" alignItems="center">
                  <Autocomplete
                    options={vagueOptions}
                    onChange={handleVagueChange}
                    renderInput={(params) => <TextField {...params} label="Vague" color='success' size='small'/>}
                    sx={{ width: 100 }}
                  />
                  <Autocomplete
                    options={anneeOptions}
                    onChange={handleAnneeChange}
                    renderInput={(params) => <TextField {...params} label="Année" color='success' size='small'/>}
                    sx={{ width: 100, marginLeft: '30px' }}
                  />
                </Box>
              </Grid>
            </Grid> 
  <Grid item xs={12} md={4} mt={2}>
  <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
        textAlign: 'center', 
        mb: { xs: 2, sm: 3 },
        color: '#333', // Couleur du texte plus prononcée
      }}
    >
      Répartition des formations par genre
    </Typography>
    <Paper elevation={3} sx={{ padding: 2, borderRadius: 4, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
    <BarChar data={chartData} />
    </Paper>
</Grid>

<Grid container spacing={4} mt={3}>
  <Grid item xs={12} md={6}>
    <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
        textAlign: 'center', 
        mb: { xs: 2, sm: 3 },
        color: '#333', // Couleur du texte plus prononcée
      }}
    >
      Formations par site
    </Typography>
    <Paper 
      elevation={3} 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',  
        width: { xs: '100%', sm: '90%', md: 'auto' }, 
        maxWidth: { xs: 320, sm: 520, md: 620 }, 
        height: { xs: 320, sm: 420, md: 420 }, 
        padding: 2, // Ajout de padding pour l'aération
        borderRadius: 3, // Arrondi du Paper
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Ombre douce
      }}
    >
      <PieChart width={400} height={400}>
        <Pie 
          data={PieData} 
          dataKey="total" 
          nameKey="name" 
          cx="50%" 
          cy="50%" 
          outerRadius={150} 
          innerRadius={60} // Ajout d'un trou intérieur pour l'effet donut
          fill="#8884d8" 
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`} // Label personnalisé
          isAnimationActive={true} // Animation activée
        >
          {PieData.map((entry, index) => (
            <Cell 
              key={`cell-${index}`} 
              fill={index % 2 === 0 ? '#42a5f5' : '#66bb6a'} // Couleurs vibrantes
              stroke="#fff" // Contour blanc pour plus de contraste
              strokeWidth={2} // Épaisseur du contour
            />
          ))}
        </Pie>
        <Tooltip 
          formatter={(value) => `${value} formations`} // Tooltip personnalisé
          cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} // Ajout d'une ombre douce sur hover
        />
      </PieChart>
    </Paper>
  </Grid>
  <Grid item xs={12} md={6}>
  <Typography 
    variant="h6" 
    sx={{ 
      fontWeight: 'bold', 
      fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
      textAlign: 'center', 
      mb: { xs: 2, sm: 3 },
      color: '#333', // Couleur du texte plus prononcée
    }}
  >
    Formations par niveau d'étude
  </Typography>
  <Paper 
    elevation={3} 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',  
      width: { xs: '100%', sm: '90%', md: 'auto' }, 
      maxWidth: { xs: 320, sm: 520, md: 620 }, 
      height: { xs: 320, sm: 420, md: 420 }, 
      padding: 2, // Ajout de padding pour l'aération
      borderRadius: 3, // Arrondi du Paper
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Ombre douce
    }}
  >
    <PieChart width={400} height={400}>
      <Pie 
        data={niveauPieData} 
        dataKey="total" 
        nameKey="name" 
        cx="50%" 
        cy="50%" 
        outerRadius={150} 
        innerRadius={60} // Effet donut pour un look plus moderne
        fill="#4bc0c0" 
        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`} // Label personnalisé
        isAnimationActive={true} // Animation activée
      >
        {niveauPieData.map((entry, index) => (
          <Cell 
            key={`cell-${index}`} 
            fill={index % 2 === 0 ? 'rgba(75, 192, 192, 0.8)' : 'rgba(160, 32, 240, 0.7)'} // Couleurs vibrantes
            stroke="#fff" // Contour blanc pour plus de contraste
            strokeWidth={2} // Épaisseur du contour
          />
        ))}
      </Pie>
      <Tooltip 
        formatter={(value) => `${value} étudiants`} // Tooltip personnalisé
        cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} // Ombre douce sur hover
      />
    </PieChart>
  </Paper>
</Grid>
</Grid>
<Grid item xs={12} md={6} mt={3}>
  <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, textAlign: 'center', mb: { xs: 2, sm: 3 } }}>
  Répartition des formations par tranche d'âge
  </Typography>
  <Paper 
    elevation={3} 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',  
      width: { xs: '100%', sm: '90%', md: 'auto' }, 
      maxWidth: { xs: 360, sm: 620, md: 1000 }, 
      height: { xs: 320, sm: 420, md: 420 }, 
      padding: 2, // Ajout de padding pour aérer
      borderRadius: 3, // Arrondi du Paper
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Ombre douce
    }}
  >
    <BarChart
      series={[{ 
        data: ageData.values, 
        label: 'Formations par âge', 
        id: 'age',
        color: '#42a5f5', // Couleur plus vive
        barRadius: [10, 10, 0, 0] // Bords arrondis en haut
      }]}
      xAxis={[{
        scaleType: 'band', 
        data: ageData.labels, 
        colorMap: { 
          type: 'ordinal', 
          colors: ['#F6CA11', '#02834A', '#FF7043', '#8E44AD'] 
        },
        axisLine: null,  // Ligne d'axe masquée
        tickLine: null,  // Marques de graduation masquées
        tickLabelStyle: { fontSize: '0.9rem', fontWeight: 'bold' }, 
      }]}
      yAxis={[{
        axisLine: null,  // Ligne d'axe masquée
        tickLine: null,  // Marques de graduation masquées
        tickLabelStyle: { fontSize: '0.9rem', fontWeight: 'bold' },
      }]}
      animationDuration={1000}
    />
  </Paper>
</Grid>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Mecanicien'])(FormationIrrigation);
