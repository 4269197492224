export const commercials=[
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0136",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-05-31 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Butteuse pour motoculteur Mahindra",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "1000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59501",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "BCVO00470",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564eee",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0136'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:04:16",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00470'",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109312",
        "cbModification": "2023-06-14 14:04:16",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0137",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-05-31 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Cultivateur pour motoculteur Mahindra",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "2000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59502",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "BCVO00470",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564eef",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0137'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:04:23",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00470'",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109313",
        "cbModification": "2023-06-14 14:04:23",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0138",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-05-31 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Roue à cage pour motoculteur Mahindra",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "3000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59503",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "BCVO00470",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564ef0",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0138'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:04:30",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00470'",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109314",
        "cbModification": "2023-06-14 14:04:30",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0139",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-05-31 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Charrue pour motoculteur Mahindra",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "4000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59504",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "BCVO00470",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564ef1",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0139'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:04:37",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00470'",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109315",
        "cbModification": "2023-06-14 14:04:37",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "(A-EX)",
        "AG_No1": "11",
        "AG_No2": "0",
        "AR_Ref": "KIT0011",
        "AR_RefCompose": "KIT0011",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "15",
        "CT_Num": "CLTYIS",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "0",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-01-25 00:00:00",
        "DL_DateBL": "2023-10-06 00:00:00",
        "DL_DateDE": "2023-12-09 00:00:00",
        "DL_DatePL": "2023-12-09 00:00:00",
        "DL_Design": "KIT TRACTEUR 60Cv PA22",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "2000",
        "DL_MontantHT": "11253158.000000",
        "DL_MontantTTC": "11253158.000000",
        "DL_MvtStock": "0",
        "DL_No": "59508",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "11253158.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "11253158.000000",
        "DL_PieceBC": "BCVO00020",
        "DL_PieceBL": "BLVO01591",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "11253158.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-12-09 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "6",
        "DO_Domaine": "0",
        "DO_Piece": "FVVO00779",
        "DO_Ref": "Agri Finance",
        "DO_Type": "7",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564ef3",
        "cbAF_RefFourniss": "b'(A-EX)'",
        "cbAR_Ref": "b'KIT0011'",
        "cbAR_RefCompose": "b'KIT0011'",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "15.0",
        "cbCT_Num": "b'CLTYIS'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:09:17",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "nan",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00020'",
        "cbDL_PieceBL": "b'    BLVO01591'",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    FVVO00779'",
        "cbDO_Ref": "b'Agri Finance'",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109319",
        "cbModification": "2024-01-18 11:16:57",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0024",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "2610224.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-06-14 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Motoculteur 12 CV",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "5000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59530",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "2610224.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564f03",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0024'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:39:07",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'             '",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109341",
        "cbModification": "2023-06-14 14:39:07",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "PSF0041",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "14",
        "CT_Num": "CLTTAMOW",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "11",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-06-14 00:00:00",
        "DL_DateBL": "2023-06-14 00:00:00",
        "DL_DateDE": "2023-06-14 00:00:00",
        "DL_DatePL": "2023-06-14 00:00:00",
        "DL_Design": "Remorque 1 tonne pour motoculteur 12 CV",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "6000",
        "DL_MontantHT": "0.000000",
        "DL_MontantTTC": "0.000000",
        "DL_MvtStock": "3",
        "DL_No": "59531",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "0.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "0.000000",
        "DL_PieceBC": "",
        "DL_PieceBL": "",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "0.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-06-14 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "3",
        "DO_Domaine": "0",
        "DO_Piece": "BLVO00699",
        "DO_Ref": "",
        "DO_Type": "3",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Unité",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564f04",
        "cbAF_RefFourniss": "b''",
        "cbAR_Ref": "b'PSF0041'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "14.0",
        "cbCT_Num": "b'CLTTAMOW'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-06-14 14:39:27",
        "cbCreationUser": "B5DB28D4-358F-4EF4-9643-7D1A4CCCA9B9",
        "cbDE_No": "11.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'             '",
        "cbDL_PieceBL": "b'             '",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    BLVO00699'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "109342",
        "cbModification": "2023-06-14 14:39:27",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    },
    {
        "AC_RefClient": "",
        "AF_RefFourniss": "(A-EX)",
        "AG_No1": "0",
        "AG_No2": "0",
        "AR_Ref": "007202501B91",
        "AR_RefCompose": "None",
        "CA_No": "0",
        "CA_Num": "",
        "CO_No": "8",
        "CT_Num": "CLTBBGT",
        "Chef Eq.": "None",
        "Conteneur": "None",
        "DE_No": "15",
        "DL_CMUP": "0.000000",
        "DL_CodeTaxe1": "None",
        "DL_CodeTaxe2": "None",
        "DL_CodeTaxe3": "None",
        "DL_DateAvancement": "1753-01-01 00:00:00",
        "DL_DateBC": "2023-05-25 00:00:00",
        "DL_DateBL": "2023-05-25 00:00:00",
        "DL_DateDE": "2023-08-25 00:00:00",
        "DL_DatePL": "2023-08-25 00:00:00",
        "DL_Design": "HYDRAULIC PUMP(12CCREV )HORIZONI (605DI)",
        "DL_Escompte": "0",
        "DL_FactPoids": "0",
        "DL_Frais": "0.000000",
        "DL_Ligne": "1000",
        "DL_MontantHT": "130000.000000",
        "DL_MontantTTC": "130000.000000",
        "DL_MvtStock": "3",
        "DL_No": "75382",
        "DL_NoColis": "",
        "DL_NoLink": "0",
        "DL_NoRef": "1",
        "DL_NoSousTotal": "0",
        "DL_NonLivre": "0",
        "DL_Operation": "",
        "DL_PUBC": "130000.000000",
        "DL_PUDevise": "0.000000",
        "DL_PUTTC": "130000.000000",
        "DL_PieceBC": "BCVO00757",
        "DL_PieceBL": "BLVO01073",
        "DL_PieceDE": "",
        "DL_PieceOFProd": "0",
        "DL_PiecePL": "",
        "DL_PoidsBrut": "0.000000",
        "DL_PoidsNet": "0.000000",
        "DL_PrixRU": "0.000000",
        "DL_PrixUnitaire": "130000.000000",
        "DL_Qte": "1.000000",
        "DL_QteBC": "1.000000",
        "DL_QteBL": "1.000000",
        "DL_QteDE": "1.000000",
        "DL_QtePL": "1.000000",
        "DL_QteRessource": "0",
        "DL_Remise01REM_Type": "0",
        "DL_Remise01REM_Valeur": "0.000000",
        "DL_Remise02REM_Type": "0",
        "DL_Remise02REM_Valeur": "0.000000",
        "DL_Remise03REM_Type": "0",
        "DL_Remise03REM_Valeur": "0.000000",
        "DL_TNomencl": "0",
        "DL_TRemExep": "0",
        "DL_TRemPied": "0",
        "DL_TTC": "0",
        "DL_Taxe1": "0.000000",
        "DL_Taxe2": "0.000000",
        "DL_Taxe3": "0.000000",
        "DL_TypePL": "0",
        "DL_TypeTaux1": "0",
        "DL_TypeTaux2": "0",
        "DL_TypeTaux3": "0",
        "DL_TypeTaxe1": "0",
        "DL_TypeTaxe2": "0",
        "DL_TypeTaxe3": "0",
        "DL_Valorise": "1",
        "DO_Date": "2023-08-25 00:00:00",
        "DO_DateLivr": "1753-01-01 00:00:00",
        "DO_DocType": "6",
        "DO_Domaine": "0",
        "DO_Piece": "FVVO00398",
        "DO_Ref": "",
        "DO_Type": "7",
        "DT_No": "0",
        "Date arrivée": "NaT",
        "Date début": "NaT",
        "Date dépotage": "NaT",
        "Date fin": "NaT",
        "Date sortie": "NaT",
        "Durée": "None",
        "Dépotage prévu": "None",
        "EU_Enumere": "Pièce",
        "EU_Qte": "1.000000",
        "Export Invoice No": "None",
        "Heure début": "None",
        "Heure fin": "None",
        "Lieu dépotage": "None",
        "Nbr Op": "None",
        "No BL": "None",
        "Observation": "None",
        "PF_Num": "",
        "RP_Code": "None",
        "Service": "None",
        "Site livraison": "None",
        "_id": "666070f094e9b22a94564fc7",
        "cbAF_RefFourniss": "b'(A-EX)'",
        "cbAR_Ref": "b'007202501B91'",
        "cbAR_RefCompose": "b''",
        "cbCA_No": "None",
        "cbCA_Num": "b''",
        "cbCO_No": "8.0",
        "cbCT_Num": "b'CLTBBGT'",
        "cbCreateur": "ERP1",
        "cbCreation": "2023-07-05 17:44:47",
        "cbCreationUser": "BD5CE137-F486-4062-B07D-41F896CDC0DD",
        "cbDE_No": "15.0",
        "cbDL_NoLink": "None",
        "cbDL_PieceBC": "b'    BCVO00757'",
        "cbDL_PieceBL": "b'    BLVO01073'",
        "cbDL_PieceDE": "b'             '",
        "cbDL_PiecePL": "b'             '",
        "cbDO_Piece": "b'    FVVO00398'",
        "cbDO_Ref": "b''",
        "cbDT_No": "nan",
        "cbFlag": "0",
        "cbHash": "None",
        "cbHashDate": "NaT",
        "cbHashOrder": "nan",
        "cbHashVersion": "1",
        "cbMarq": "131799",
        "cbModification": "2023-10-18 20:01:47",
        "cbPF_Num": "b'         '",
        "cbProt": "0",
        "cbRP_Code": "b''",
        "cbReplication": "0"
    }]