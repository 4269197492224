const datas=[
    {
        "montant_total_ventes": 28191005.0,
        "zone": "sinende "
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "kokiborou"
    },
    {
        "montant_total_ventes": 15562708.0,
        "zone": "sinaboro/banikoara"
    },
    {
        "montant_total_ventes": 14002284.0,
        "zone": "pehnco/atacora"
    },
    {
        "montant_total_ventes": 620000.0,
        "zone": "tori-djidja"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "kaklalé"
    },
    {
        "montant_total_ventes": 28363618.0,
        "zone": "gogounou/bagou"
    },
    {
        "montant_total_ventes": 5504598.0,
        "zone": "banikoara/kokiborou"
    },
    {
        "montant_total_ventes": 29566000.0,
        "zone": "agbangnizoun"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "sékou"
    },
    {
        "montant_total_ventes": 12973522.0,
        "zone": "gogounou/badon"
    },
    {
        "montant_total_ventes": 119716495.0,
        "zone": "ina"
    },
    {
        "montant_total_ventes": 25544016.0,
        "zone": "donga/ouake"
    },
    {
        "montant_total_ventes": 49785523.0,
        "zone": "sinendÉ/borgou"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "nikki/biro/gnanhoun"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "bembereke/bouanri"
    },
    {
        "montant_total_ventes": 16468923.0,
        "zone": "djougou/barienou"
    },
    {
        "montant_total_ventes": 40365672.0,
        "zone": "sinendé / borgou"
    },
    {
        "montant_total_ventes": 19607000.0,
        "zone": "collines/glazoue"
    },
    {
        "montant_total_ventes": 17317200.0,
        "zone": "ifagni"
    },
    {
        "montant_total_ventes": 305147940.0,
        "zone": "pobè"
    },
    {
        "montant_total_ventes": 37353514.0,
        "zone": "kÉrou/atacora"
    },
    {
        "montant_total_ventes": 7881000.0,
        "zone": "c/1075 cotonou/minonkpo "
    },
    {
        "montant_total_ventes": 43978205.0,
        "zone": "kouande/atacora"
    },
    {
        "montant_total_ventes": 69141057.0,
        "zone": "natitingou/atacora"
    },
    {
        "montant_total_ventes": 20182836.0,
        "zone": "gamia/bembereke"
    },
    {
        "montant_total_ventes": 19607000.0,
        "zone": "plateau/pobe"
    },
    {
        "montant_total_ventes": 370454867.0,
        "zone": "ségbana"
    },
    {
        "montant_total_ventes": 211874866.0,
        "zone": "lokossa"
    },
    {
        "montant_total_ventes": 49325055.0,
        "zone": "dogbo"
    },
    {
        "montant_total_ventes": 11964027.0,
        "zone": "sinendé/guessou-bani/borgou"
    },
    {
        "montant_total_ventes": 37762411.0,
        "zone": "tori-bossito"
    },
    {
        "montant_total_ventes": 19385474.0,
        "zone": "donga / kolokonde / bari"
    },
    {
        "montant_total_ventes": 143000.0,
        "zone": "bantè"
    },
    {
        "montant_total_ventes": 17250000.0,
        "zone": "goungoun/guene"
    },
    {
        "montant_total_ventes": 245918816.0,
        "zone": "sinendé"
    },
    {
        "montant_total_ventes": 64130024.0,
        "zone": "kouandé"
    },
    {
        "montant_total_ventes": 11007000.0,
        "zone": "atacora / tanguiÉta "
    },
    {
        "montant_total_ventes": 70799723.0,
        "zone": "goua"
    },
    {
        "montant_total_ventes": 13877000.0,
        "zone": "yarikou/natitingou "
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "djougou/onklou/bakou"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "bembereke/beroubouay"
    },
    {
        "montant_total_ventes": 25544016.0,
        "zone": "pehunco /borgou "
    },
    {
        "montant_total_ventes": 41684158.0,
        "zone": "n'dali "
    },
    {
        "montant_total_ventes": 11627842.0,
        "zone": " sinende"
    },
    {
        "montant_total_ventes": 12122378.0,
        "zone": "zagnanado"
    },
    {
        "montant_total_ventes": 13232440.0,
        "zone": "gah-maro/nikki"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "donga/bassila / preketè"
    },
    {
        "montant_total_ventes": 38075693.0,
        "zone": "atacora/tanguieta"
    },
    {
        "montant_total_ventes": 41488124.0,
        "zone": "guinagourou"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "djougou/partago"
    },
    {
        "montant_total_ventes": 17177665.0,
        "zone": "dagourou"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "perere/gninagourou"
    },
    {
        "montant_total_ventes": 3007000.0,
        "zone": "arouna ayouba"
    },
    {
        "montant_total_ventes": 14699646.0,
        "zone": "cobly/tapoga"
    },
    {
        "montant_total_ventes": 173400.0,
        "zone": "seto"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "sekere-gando/sinende"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "mami"
    },
    {
        "montant_total_ventes": 13450000.0,
        "zone": "zou/zagnanado centre"
    },
    {
        "montant_total_ventes": 5510000.0,
        "zone": "kpataba"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "sèmè-podji"
    },
    {
        "montant_total_ventes": 24257667.0,
        "zone": "pèrèrè/sinendé"
    },
    {
        "montant_total_ventes": 20182836.0,
        "zone": "n'dali/borgou"
    },
    {
        "montant_total_ventes": 549585350.0,
        "zone": "pointe noire "
    },
    {
        "montant_total_ventes": 161172563.0,
        "zone": "boukoumbe/atacora"
    },
    {
        "montant_total_ventes": 27690096.0,
        "zone": "teme/bori/n'dali"
    },
    {
        "montant_total_ventes": 9910000.0,
        "zone": "zogbodomey/hlagba"
    },
    {
        "montant_total_ventes": 242338279.0,
        "zone": "sinendé/borgou"
    },
    {
        "montant_total_ventes": 40777000.0,
        "zone": "ouesse/collines"
    },
    {
        "montant_total_ventes": 327853043.0,
        "zone": "savalou"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "kerou/sinagourou"
    },
    {
        "montant_total_ventes": 3000000.0,
        "zone": "abomey-calavi "
    },
    {
        "montant_total_ventes": 12421627.0,
        "zone": "segbana "
    },
    {
        "montant_total_ventes": 9544411.0,
        "zone": "oueme"
    },
    {
        "montant_total_ventes": 134000.0,
        "zone": "ouassa /pehunco"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "atchakitam"
    },
    {
        "montant_total_ventes": 12162708.0,
        "zone": "kandi/alibori"
    },
    {
        "montant_total_ventes": 27216402.0,
        "zone": "sahoue"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "pobe/plateau"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "sam-wonga / kandi"
    },
    {
        "montant_total_ventes": 27498020.0,
        "zone": "seme podji"
    },
    {
        "montant_total_ventes": 17448584.0,
        "zone": "sontou/perere"
    },
    {
        "montant_total_ventes": 2589727.0,
        "zone": "kokoko"
    },
    {
        "montant_total_ventes": 20000000.0,
        "zone": "ouake/donga"
    },
    {
        "montant_total_ventes": 254500.0,
        "zone": "malanville "
    },
    {
        "montant_total_ventes": 18774269.0,
        "zone": "alafiarou/tchaourou"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "diguidirou/perere"
    },
    {
        "montant_total_ventes": 62556239.0,
        "zone": "boukoumbe"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "gamia /bembereke"
    },
    {
        "montant_total_ventes": 117700.0,
        "zone": "abomey/djidja"
    },
    {
        "montant_total_ventes": 26500000.0,
        "zone": "madjre centre"
    },
    {
        "montant_total_ventes": 3669732.0,
        "zone": "birni lafia / karimama "
    },
    {
        "montant_total_ventes": 64750.0,
        "zone": "ouidah/gakpe"
    },
    {
        "montant_total_ventes": 7881000.0,
        "zone": "ekpe"
    },
    {
        "montant_total_ventes": 2358727.0,
        "zone": "gbegourou"
    },
    {
        "montant_total_ventes": 27759473.0,
        "zone": "zougou"
    },
    {
        "montant_total_ventes": 219365914.0,
        "zone": "perere"
    },
    {
        "montant_total_ventes": 25544016.0,
        "zone": "djakotomey/gbotohoue"
    },
    {
        "montant_total_ventes": 11040727.0,
        "zone": "pehunco/soaodou"
    },
    {
        "montant_total_ventes": 64062875.0,
        "zone": "sekere / sinendé"
    },
    {
        "montant_total_ventes": 16840000.0,
        "zone": "niamey/niger"
    },
    {
        "montant_total_ventes": 12283537.0,
        "zone": "kalale/banezi"
    },
    {
        "montant_total_ventes": 16375062.0,
        "zone": "banikoara/kobiborou"
    },
    {
        "montant_total_ventes": 42224130.0,
        "zone": "pehunco "
    },
    {
        "montant_total_ventes": 30869000.0,
        "zone": "abomey calavi"
    },
    {
        "montant_total_ventes": 14527033.0,
        "zone": "yarra-kouri/sinende"
    },
    {
        "montant_total_ventes": 31107743.0,
        "zone": "djougou/ pénébina"
    },
    {
        "montant_total_ventes": 4273876.0,
        "zone": "toucoutouna"
    },
    {
        "montant_total_ventes": 24607000.0,
        "zone": "gogounou/alibori"
    },
    {
        "montant_total_ventes": 1162555800.0,
        "zone": "ouagadougou"
    },
    {
        "montant_total_ventes": 74141016.0,
        "zone": "sèhouè"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "bagou/garagoro"
    },
    {
        "montant_total_ventes": 25544016.0,
        "zone": "basso"
    },
    {
        "montant_total_ventes": 164400.0,
        "zone": "adja-ouèrè"
    },
    {
        "montant_total_ventes": 23000.0,
        "zone": "ouassa penhunco"
    },
    {
        "montant_total_ventes": 21560000.0,
        "zone": "togbin-daho"
    },
    {
        "montant_total_ventes": 17116220.0,
        "zone": "n'dali/maregourou"
    },
    {
        "montant_total_ventes": 72110627.0,
        "zone": "ina "
    },
    {
        "montant_total_ventes": 42480562.0,
        "zone": "guinangourou"
    },
    {
        "montant_total_ventes": 72104000.0,
        "zone": "agbodji"
    },
    {
        "montant_total_ventes": 13958943.0,
        "zone": "ndali"
    },
    {
        "montant_total_ventes": 10727000.0,
        "zone": " abomey-calavi/kpanroun"
    },
    {
        "montant_total_ventes": 29960282.0,
        "zone": "gounarou/gogounou"
    },
    {
        "montant_total_ventes": 15366421.0,
        "zone": "sokotindji"
    },
    {
        "montant_total_ventes": 3750.0,
        "zone": "ouassa/pehunco"
    },
    {
        "montant_total_ventes": 37490062.0,
        "zone": "maregourou"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "tchaourou/kika"
    },
    {
        "montant_total_ventes": 522900.0,
        "zone": "tokan/abomey-calavi"
    },
    {
        "montant_total_ventes": 27952000.0,
        "zone": "toffo"
    },
    {
        "montant_total_ventes": 120000.0,
        "zone": "adja-honmin"
    },
    {
        "montant_total_ventes": 995000.0,
        "zone": "littoral/cotonou"
    },
    {
        "montant_total_ventes": 42720000.0,
        "zone": "glazoue"
    },
    {
        "montant_total_ventes": 58800.0,
        "zone": "ganmia"
    },
    {
        "montant_total_ventes": 35000.0,
        "zone": "teme "
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "pehunco/firon"
    },
    {
        "montant_total_ventes": 13450000.0,
        "zone": "kpedekpo"
    },
    {
        "montant_total_ventes": 1524143513.0,
        "zone": "porto-novo"
    },
    {
        "montant_total_ventes": 12300000.0,
        "zone": "djougou/penebina/goumbakou"
    },
    {
        "montant_total_ventes": 169823173.0,
        "zone": "cobly"
    },
    {
        "montant_total_ventes": 117417192.0,
        "zone": "sirarou"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "mareri/atacora"
    },
    {
        "montant_total_ventes": 19299167.0,
        "zone": "guesou sud"
    },
    {
        "montant_total_ventes": 260549391.0,
        "zone": "djidja"
    },
    {
        "montant_total_ventes": 170000.0,
        "zone": "founougo/banikoara"
    },
    {
        "montant_total_ventes": 390000.0,
        "zone": "masse"
    },
    {
        "montant_total_ventes": 42078437.0,
        "zone": "tanguieta / atacora"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "teti/ganro/bembereke"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "borgou/nikki"
    },
    {
        "montant_total_ventes": 50142368.0,
        "zone": "tanguiéta"
    },
    {
        "montant_total_ventes": 40946200.0,
        "zone": "kouandé/birni"
    },
    {
        "montant_total_ventes": 10074330.0,
        "zone": "ouassa-pehunco"
    },
    {
        "montant_total_ventes": 97875000.0,
        "zone": "gdiz - benin"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "ketou/odometa"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "savi/ouidah"
    },
    {
        "montant_total_ventes": 47270564.0,
        "zone": "sam/kandi"
    },
    {
        "montant_total_ventes": 310000.0,
        "zone": "ouesse/segbanou"
    },
    {
        "montant_total_ventes": 4825001.0,
        "zone": "abomey-calavi / parana"
    },
    {
        "montant_total_ventes": 140857298.0,
        "zone": "kalale"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "ouenou"
    },
    {
        "montant_total_ventes": 15956000.0,
        "zone": "dassa"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "tebo/biro"
    },
    {
        "montant_total_ventes": 10074330.0,
        "zone": "banikoara/ounet"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "sokka/sinende"
    },
    {
        "montant_total_ventes": 20504976.0,
        "zone": " bougou fana"
    },
    {
        "montant_total_ventes": 17436584.0,
        "zone": "gamaro/nikki"
    },
    {
        "montant_total_ventes": 26694016.0,
        "zone": "cove"
    },
    {
        "montant_total_ventes": 423750.0,
        "zone": "manigri/bassila"
    },
    {
        "montant_total_ventes": 40331336.0,
        "zone": "kouande /atacora"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "yimpissiri/cobly"
    },
    {
        "montant_total_ventes": 48228000.0,
        "zone": "aplahoue"
    },
    {
        "montant_total_ventes": 64644586.0,
        "zone": "adja-ouere"
    },
    {
        "montant_total_ventes": 253500.0,
        "zone": "tori acadjamè"
    },
    {
        "montant_total_ventes": 50466000.0,
        "zone": "seme podji/porto-novo"
    },
    {
        "montant_total_ventes": 35058211.0,
        "zone": "bembereke/gamia"
    },
    {
        "montant_total_ventes": 18968011.0,
        "zone": "akassato/abomey-calavi "
    },
    {
        "montant_total_ventes": 30203930.0,
        "zone": "bembereke/ina"
    },
    {
        "montant_total_ventes": 12028000.0,
        "zone": "dassa-zoume"
    },
    {
        "montant_total_ventes": 955036658.0,
        "zone": "segbana"
    },
    {
        "montant_total_ventes": 66000.0,
        "zone": "fidjrosse"
    },
    {
        "montant_total_ventes": 43850662.0,
        "zone": "kérou/atacora"
    },
    {
        "montant_total_ventes": 12600.0,
        "zone": "tanguieta/tanongou"
    },
    {
        "montant_total_ventes": 222754030.0,
        "zone": "kerou /atacora"
    },
    {
        "montant_total_ventes": 11213340.0,
        "zone": "sinaourarou/pehunco"
    },
    {
        "montant_total_ventes": 26637492.0,
        "zone": "toucountouna"
    },
    {
        "montant_total_ventes": 18447534.0,
        "zone": "dassa/paouingnan"
    },
    {
        "montant_total_ventes": 33407356.0,
        "zone": "ouake"
    },
    {
        "montant_total_ventes": 13717950.0,
        "zone": "bante"
    },
    {
        "montant_total_ventes": 91903104.0,
        "zone": "atacora/materi"
    },
    {
        "montant_total_ventes": 14637819.0,
        "zone": "tcharorou "
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kouandé  /atacora "
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "pobe "
    },
    {
        "montant_total_ventes": 38061424.0,
        "zone": "kokiborou/banikoara"
    },
    {
        "montant_total_ventes": 12335321.0,
        "zone": "segbana/alibori"
    },
    {
        "montant_total_ventes": 16000.0,
        "zone": "azove"
    },
    {
        "montant_total_ventes": 392000.0,
        "zone": "kpomasse/couffonou"
    },
    {
        "montant_total_ventes": 350000.0,
        "zone": "glo djigbé"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "soaodou/pehunco"
    },
    {
        "montant_total_ventes": 30521273.0,
        "zone": "kerou/firou"
    },
    {
        "montant_total_ventes": 201309384.0,
        "zone": "pèrèrè"
    },
    {
        "montant_total_ventes": 24567074.0,
        "zone": "kalale/basso"
    },
    {
        "montant_total_ventes": 530414819.0,
        "zone": "kalalé"
    },
    {
        "montant_total_ventes": 108426761.0,
        "zone": "bassila"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": ": djougou/partago"
    },
    {
        "montant_total_ventes": 37700000.0,
        "zone": "mono/athieme"
    },
    {
        "montant_total_ventes": 7353967.0,
        "zone": "banikandi/parakou"
    },
    {
        "montant_total_ventes": 17600000.0,
        "zone": "agla/cotonou"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kerou/pakire"
    },
    {
        "montant_total_ventes": 12300000.0,
        "zone": "n'dali / gbegourou"
    },
    {
        "montant_total_ventes": 18312901.0,
        "zone": "kaobagou"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "gounarou"
    },
    {
        "montant_total_ventes": 1950000.0,
        "zone": "matékpon"
    },
    {
        "montant_total_ventes": 1645823686.0,
        "zone": "n'dali"
    },
    {
        "montant_total_ventes": 14677211.0,
        "zone": "banikoara "
    },
    {
        "montant_total_ventes": 6173921.0,
        "zone": "savi"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "assode"
    },
    {
        "montant_total_ventes": 47286000.0,
        "zone": "agblangandan"
    },
    {
        "montant_total_ventes": 15291340.0,
        "zone": "kori/bori/n'dali"
    },
    {
        "montant_total_ventes": 125000.0,
        "zone": "gbetagbo"
    },
    {
        "montant_total_ventes": 2093781.0,
        "zone": "abomey/zou"
    },
    {
        "montant_total_ventes": 444900.0,
        "zone": "ketou "
    },
    {
        "montant_total_ventes": 181000.0,
        "zone": "bembèrèkè/gamia"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "baka/parakou"
    },
    {
        "montant_total_ventes": 18388833.0,
        "zone": "kouande/makrou"
    },
    {
        "montant_total_ventes": 12300000.0,
        "zone": "glazoue/aklampa"
    },
    {
        "montant_total_ventes": 1021788793.0,
        "zone": "parakou"
    },
    {
        "montant_total_ventes": 36641075.0,
        "zone": "pobè/towe/igbo-ocho"
    },
    {
        "montant_total_ventes": 3048514712.0,
        "zone": "glo-djigbe industrial zone-benin"
    },
    {
        "montant_total_ventes": 21000000.0,
        "zone": "kpassa/okpara"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "kobly"
    },
    {
        "montant_total_ventes": 140000.0,
        "zone": "akpakpa"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "n'dali/bori  sonnoumon"
    },
    {
        "montant_total_ventes": 6000.0,
        "zone": "sonnoumon"
    },
    {
        "montant_total_ventes": 10764780.0,
        "zone": "libante/segbana"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kerou/sonwene"
    },
    {
        "montant_total_ventes": 24600000.0,
        "zone": "guinagourou/perere"
    },
    {
        "montant_total_ventes": 1248078573.0,
        "zone": "gogounou"
    },
    {
        "montant_total_ventes": 33781909.0,
        "zone": "toura/banikoara"
    },
    {
        "montant_total_ventes": 15303789.0,
        "zone": "zougou / kpantrossi"
    },
    {
        "montant_total_ventes": 22250356.0,
        "zone": "fo bouré/ sinendé/borgou"
    },
    {
        "montant_total_ventes": 106467026.0,
        "zone": "donga/djougou"
    },
    {
        "montant_total_ventes": 166400.0,
        "zone": "natti centre st paul"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "atacora/kouande"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "pikire/kerou"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "abomey-calavi/akassato"
    },
    {
        "montant_total_ventes": 763768357.0,
        "zone": "malanville"
    },
    {
        "montant_total_ventes": 13043032.0,
        "zone": "banikoara/gogounou"
    },
    {
        "montant_total_ventes": 70500036.0,
        "zone": "marégourou"
    },
    {
        "montant_total_ventes": 193900.0,
        "zone": "ouidah/hounkpe"
    },
    {
        "montant_total_ventes": 50087900.0,
        "zone": "toho"
    },
    {
        "montant_total_ventes": 47286000.0,
        "zone": "sekandji/ seme kpodji"
    },
    {
        "montant_total_ventes": 17360000.0,
        "zone": "abidjan"
    },
    {
        "montant_total_ventes": 2347924462.0,
        "zone": "kandi"
    },
    {
        "montant_total_ventes": 680627845.0,
        "zone": "nikki"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "tchetti/savalou"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "n'dali/bangueou"
    },
    {
        "montant_total_ventes": 16977901.0,
        "zone": "sonnoumon/bori/n'dali"
    },
    {
        "montant_total_ventes": 18174330.0,
        "zone": "karimama"
    },
    {
        "montant_total_ventes": 20182836.0,
        "zone": "tokey/banikoara"
    },
    {
        "montant_total_ventes": 146000.0,
        "zone": "abomey-tangandji"
    },
    {
        "montant_total_ventes": 25656416.0,
        "zone": "ouaké"
    },
    {
        "montant_total_ventes": 50882554.0,
        "zone": "djougou/donga"
    },
    {
        "montant_total_ventes": 130780755.0,
        "zone": "république démocratique du congo"
    },
    {
        "montant_total_ventes": 994400.0,
        "zone": "kpovié/ouidah"
    },
    {
        "montant_total_ventes": 148540368.0,
        "zone": "calavi"
    },
    {
        "montant_total_ventes": 6556000.0,
        "zone": "agbon/bante"
    },
    {
        "montant_total_ventes": 2358727.0,
        "zone": "avrankou/oueme "
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "sinende/kouri"
    },
    {
        "montant_total_ventes": 36381000.0,
        "zone": "adjohoun"
    },
    {
        "montant_total_ventes": 373179055.0,
        "zone": "savè"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "tota"
    },
    {
        "montant_total_ventes": 2452000.0,
        "zone": " athiemey"
    },
    {
        "montant_total_ventes": 1600001.0,
        "zone": "donga/bassila"
    },
    {
        "montant_total_ventes": 28267816.0,
        "zone": "tanguiéta/atacora"
    },
    {
        "montant_total_ventes": 1280000.0,
        "zone": "tanguiete"
    },
    {
        "montant_total_ventes": 69894500.0,
        "zone": "pahou"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "yam sale"
    },
    {
        "montant_total_ventes": 36730115.0,
        "zone": "parakou/korobororou"
    },
    {
        "montant_total_ventes": 3669732.0,
        "zone": "birni lafia"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "lalo/dogbo"
    },
    {
        "montant_total_ventes": 16098619.0,
        "zone": "kpomassè/ouidah"
    },
    {
        "montant_total_ventes": 41664076.0,
        "zone": "bembereke "
    },
    {
        "montant_total_ventes": 13877000.0,
        "zone": "bassila/aoro"
    },
    {
        "montant_total_ventes": 5504598.0,
        "zone": "birni lafia / karimama"
    },
    {
        "montant_total_ventes": 26966986.0,
        "zone": "basso/kalale"
    },
    {
        "montant_total_ventes": 16577518.0,
        "zone": "adjougou"
    },
    {
        "montant_total_ventes": 80505584.0,
        "zone": "atacora/kerou"
    },
    {
        "montant_total_ventes": 7881000.0,
        "zone": "allada, atlantique"
    },
    {
        "montant_total_ventes": 4413727.0,
        "zone": "segbena"
    },
    {
        "montant_total_ventes": 16577518.0,
        "zone": "dasso-ayizando"
    },
    {
        "montant_total_ventes": 29407000.0,
        "zone": "porto novo"
    },
    {
        "montant_total_ventes": 6872612296.0,
        "zone": "cotonou"
    },
    {
        "montant_total_ventes": 13731177.0,
        "zone": "pehunco/gnémansson"
    },
    {
        "montant_total_ventes": 13750278.0,
        "zone": "bembereke/goua"
    },
    {
        "montant_total_ventes": 11213340.0,
        "zone": "bari/atacora"
    },
    {
        "montant_total_ventes": 90000.0,
        "zone": "atlantique/ouidah/agbanou"
    },
    {
        "montant_total_ventes": 15044393.0,
        "zone": "bembereke /ina"
    },
    {
        "montant_total_ventes": 182000.0,
        "zone": "womey "
    },
    {
        "montant_total_ventes": 13800.0,
        "zone": "gbessou"
    },
    {
        "montant_total_ventes": 37012000.0,
        "zone": "gommey/pèrèrè"
    },
    {
        "montant_total_ventes": 38282364.0,
        "zone": "sonsoro/kandi"
    },
    {
        "montant_total_ventes": 50837616.0,
        "zone": "save"
    },
    {
        "montant_total_ventes": 25544016.0,
        "zone": "glazoué"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "gbianso/bori/n'dali"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "soubo/nikki"
    },
    {
        "montant_total_ventes": 5548000.0,
        "zone": "sehoue/toffo"
    },
    {
        "montant_total_ventes": 20182836.0,
        "zone": "batérou / colline"
    },
    {
        "montant_total_ventes": 13450000.0,
        "zone": "houeyogbe"
    },
    {
        "montant_total_ventes": 14785952.0,
        "zone": "bouagnissou/tanguieta"
    },
    {
        "montant_total_ventes": 8800000.0,
        "zone": "nanebou/tanguieta"
    },
    {
        "montant_total_ventes": 43189211.0,
        "zone": "borgou/n'dali"
    },
    {
        "montant_total_ventes": 60042860.0,
        "zone": "matéri"
    },
    {
        "montant_total_ventes": 15543796.0,
        "zone": "bori/n'dali"
    },
    {
        "montant_total_ventes": 14611000.0,
        "zone": "ouesse- wogoudou"
    },
    {
        "montant_total_ventes": 57077765.0,
        "zone": "goumori/banikoara"
    },
    {
        "montant_total_ventes": 3007000.0,
        "zone": "pehunco/gountia"
    },
    {
        "montant_total_ventes": 51348032.0,
        "zone": "djakotomey"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "bori/maregourou"
    },
    {
        "montant_total_ventes": 16464632.0,
        "zone": "madina/djougou"
    },
    {
        "montant_total_ventes": 31815866.0,
        "zone": "kerou/bambaba"
    },
    {
        "montant_total_ventes": 20713002.0,
        "zone": "tanguieta "
    },
    {
        "montant_total_ventes": 6304000.0,
        "zone": "zogbodomey"
    },
    {
        "montant_total_ventes": 27327000.0,
        "zone": "collines/dassa-zoume"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "sikouro /ina /bembereke"
    },
    {
        "montant_total_ventes": 294000.0,
        "zone": "glo"
    },
    {
        "montant_total_ventes": 20207000.0,
        "zone": "donga / djougou /kolokonde"
    },
    {
        "montant_total_ventes": 54683207.0,
        "zone": "kerou/atacora"
    },
    {
        "montant_total_ventes": 21652000.0,
        "zone": "borgou/ tchaourou"
    },
    {
        "montant_total_ventes": 4168662893.0,
        "zone": "abomey-calavi"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kika/tchaourou"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "alibori/gogounou"
    },
    {
        "montant_total_ventes": 45182677.0,
        "zone": "bassila "
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "kandi/sonsoro"
    },
    {
        "montant_total_ventes": 82400000.0,
        "zone": "zè"
    },
    {
        "montant_total_ventes": 24143294.0,
        "zone": "tori"
    },
    {
        "montant_total_ventes": 174748273.0,
        "zone": "natitingou"
    },
    {
        "montant_total_ventes": 7881000.0,
        "zone": "seme kpodji/agblangandan"
    },
    {
        "montant_total_ventes": 15469302.0,
        "zone": "pehunco/gimbererou"
    },
    {
        "montant_total_ventes": 22007000.0,
        "zone": "mekrou/soroko"
    },
    {
        "montant_total_ventes": 28961292.0,
        "zone": "soaodou"
    },
    {
        "montant_total_ventes": 181326933.0,
        "zone": "gogouno/gounarou"
    },
    {
        "montant_total_ventes": 19607124.0,
        "zone": "lokossa/mono"
    },
    {
        "montant_total_ventes": 67400.0,
        "zone": "agonkanme / kpomasse"
    },
    {
        "montant_total_ventes": 177772063.0,
        "zone": "copargo/donga"
    },
    {
        "montant_total_ventes": 892000.0,
        "zone": "kokoro"
    },
    {
        "montant_total_ventes": 83000.0,
        "zone": "teme"
    },
    {
        "montant_total_ventes": 3138000.0,
        "zone": "togo"
    },
    {
        "montant_total_ventes": 15044871.0,
        "zone": "ningoussourou/ouassa-pehunco"
    },
    {
        "montant_total_ventes": 42127214.0,
        "zone": "gogounou "
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "sinendÉ borgou"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "donga/partago"
    },
    {
        "montant_total_ventes": 63048000.0,
        "zone": "abomey - calavi"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "parakou/assagbinin"
    },
    {
        "montant_total_ventes": 17304000.0,
        "zone": "ifangni"
    },
    {
        "montant_total_ventes": 3000000.0,
        "zone": "toucountouna/bouyagnindi"
    },
    {
        "montant_total_ventes": 906411768.0,
        "zone": "pehunco/atacora"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "djidja/abledji"
    },
    {
        "montant_total_ventes": 280000.0,
        "zone": "atacora/cobli"
    },
    {
        "montant_total_ventes": 11964027.0,
        "zone": " segbana"
    },
    {
        "montant_total_ventes": 33000.0,
        "zone": "mono/athiémé"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "nassou/pehunco"
    },
    {
        "montant_total_ventes": 45557469.0,
        "zone": "atacora/cobly"
    },
    {
        "montant_total_ventes": 18842269.0,
        "zone": "akassato"
    },
    {
        "montant_total_ventes": 14680000.0,
        "zone": "lalo- hlassame"
    },
    {
        "montant_total_ventes": 12300000.0,
        "zone": "atacora"
    },
    {
        "montant_total_ventes": 13232440.0,
        "zone": "sirikou/banikoara"
    },
    {
        "montant_total_ventes": 20600000.0,
        "zone": "bembereke/saore"
    },
    {
        "montant_total_ventes": 13359646.0,
        "zone": "sirikou"
    },
    {
        "montant_total_ventes": 325745874.0,
        "zone": "kouande"
    },
    {
        "montant_total_ventes": 11097059.0,
        "zone": "sam-peulh"
    },
    {
        "montant_total_ventes": 0.0,
        "zone": "sinende/fo-boure"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "banhounkpo"
    },
    {
        "montant_total_ventes": 9007000.0,
        "zone": "zinvie"
    },
    {
        "montant_total_ventes": 22858000.0,
        "zone": "porto-novo / oueme"
    },
    {
        "montant_total_ventes": 15000.0,
        "zone": "gakpe tokoli"
    },
    {
        "montant_total_ventes": 35379993.0,
        "zone": "tanguiéta/goro-bani"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "guilmaro-sinakpagourou"
    },
    {
        "montant_total_ventes": 15045901.0,
        "zone": "kpataba/savalou"
    },
    {
        "montant_total_ventes": 20000.0,
        "zone": "lobagbsome / ouidah"
    },
    {
        "montant_total_ventes": 31600000.0,
        "zone": " atlantique/toffo"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "bouerou/pehunco"
    },
    {
        "montant_total_ventes": 550000.0,
        "zone": "wassa-pionko / calavi houeto"
    },
    {
        "montant_total_ventes": 24600000.0,
        "zone": "kpassa/parakou"
    },
    {
        "montant_total_ventes": 186174156.0,
        "zone": "materi"
    },
    {
        "montant_total_ventes": 14280000.0,
        "zone": "ouèssè/calavi"
    },
    {
        "montant_total_ventes": 267838641.0,
        "zone": "tanguieta/atacora"
    },
    {
        "montant_total_ventes": 275000.0,
        "zone": "pin"
    },
    {
        "montant_total_ventes": 43044016.0,
        "zone": "atacora/natitingou"
    },
    {
        "montant_total_ventes": 375000.0,
        "zone": "akpakpa/ciné concorde "
    },
    {
        "montant_total_ventes": 13102000.0,
        "zone": "bassila/manigri"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "segbana/sokotindji"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "tchaourou/kpassa"
    },
    {
        "montant_total_ventes": 17436584.0,
        "zone": "boukoumbe/tchaourou"
    },
    {
        "montant_total_ventes": 18654421.0,
        "zone": "kpomasse "
    },
    {
        "montant_total_ventes": 43450000.0,
        "zone": "sikecodji"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "pehunco/gnenassou"
    },
    {
        "montant_total_ventes": 17311647.0,
        "zone": "zagnanado/abomey"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "materi/gouande"
    },
    {
        "montant_total_ventes": 13450000.0,
        "zone": "péhunco"
    },
    {
        "montant_total_ventes": 3007000.0,
        "zone": "klouékanmè/couffo"
    },
    {
        "montant_total_ventes": 15831177.0,
        "zone": "goua / bembereke"
    },
    {
        "montant_total_ventes": 18311646.0,
        "zone": "ouake /donga"
    },
    {
        "montant_total_ventes": 11627842.0,
        "zone": "toucountouna/atacora"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "kandi/saw"
    },
    {
        "montant_total_ventes": 14898912.0,
        "zone": "kpomasse"
    },
    {
        "montant_total_ventes": 5504598.0,
        "zone": "toura "
    },
    {
        "montant_total_ventes": 22760000.0,
        "zone": "mono/grand-popo"
    },
    {
        "montant_total_ventes": 87878886.0,
        "zone": "pobe"
    },
    {
        "montant_total_ventes": 244816090.0,
        "zone": "sinende/borgou"
    },
    {
        "montant_total_ventes": 17000.0,
        "zone": "oussa/pehunco"
    },
    {
        "montant_total_ventes": 9030000.0,
        "zone": "atacora /pehunco"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "gamia / bembereke"
    },
    {
        "montant_total_ventes": 23928054.0,
        "zone": "pehunco/gnémanson"
    },
    {
        "montant_total_ventes": 9800000.0,
        "zone": "atacora / natitingou "
    },
    {
        "montant_total_ventes": 401572146.0,
        "zone": "materi/atacora"
    },
    {
        "montant_total_ventes": 32300000.0,
        "zone": "gabon"
    },
    {
        "montant_total_ventes": 11964027.0,
        "zone": "kandi "
    },
    {
        "montant_total_ventes": 145384279.0,
        "zone": "gogounou/sori"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "atacora / toucountouna"
    },
    {
        "montant_total_ventes": 33933114.0,
        "zone": "toukountouna"
    },
    {
        "montant_total_ventes": 43564011.0,
        "zone": "ina/bembereke"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "tamarou"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "kpari kika"
    },
    {
        "montant_total_ventes": 26800000.0,
        "zone": "mareborou"
    },
    {
        "montant_total_ventes": 23125746.0,
        "zone": "atacora/toucountouna"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "tchaourou/sori"
    },
    {
        "montant_total_ventes": 13457000.0,
        "zone": "oueme/avrankou"
    },
    {
        "montant_total_ventes": 26637492.0,
        "zone": "atacora/pehunco"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "kerou "
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "toukoutounan"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "guessou-nord/bembereke"
    },
    {
        "montant_total_ventes": 23643000.0,
        "zone": "abomey calavi/aitchedji agori"
    },
    {
        "montant_total_ventes": 8800000.0,
        "zone": "gbegamey-cotonou"
    },
    {
        "montant_total_ventes": 76715609.0,
        "zone": "atacora/kerou "
    },
    {
        "montant_total_ventes": 37901229.0,
        "zone": "goumori"
    },
    {
        "montant_total_ventes": 24600000.0,
        "zone": "kika (parakou)"
    },
    {
        "montant_total_ventes": 381700.0,
        "zone": "kérou/pehunko"
    },
    {
        "montant_total_ventes": 8039000.0,
        "zone": "ouesse"
    },
    {
        "montant_total_ventes": 94266900.0,
        "zone": "sori/gogounou"
    },
    {
        "montant_total_ventes": 12594239.0,
        "zone": "sourari/kerou"
    },
    {
        "montant_total_ventes": 13877000.0,
        "zone": "ouinhi / aize"
    },
    {
        "montant_total_ventes": 120972451.0,
        "zone": "kika"
    },
    {
        "montant_total_ventes": 18479258.0,
        "zone": "boukoubourou/kerou"
    },
    {
        "montant_total_ventes": 6804598.0,
        "zone": "yampogou"
    },
    {
        "montant_total_ventes": 1013036.0,
        "zone": "gakpe"
    },
    {
        "montant_total_ventes": 55500000.0,
        "zone": "gdiz-benin"
    },
    {
        "montant_total_ventes": 93200.0,
        "zone": "klouékanmè"
    },
    {
        "montant_total_ventes": 27427488.0,
        "zone": "banikoara/toura"
    },
    {
        "montant_total_ventes": 13500.0,
        "zone": "savi/assogbenou"
    },
    {
        "montant_total_ventes": 10074330.0,
        "zone": "tobre/pehunco"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "n'dali/bouyerou"
    },
    {
        "montant_total_ventes": 25607000.0,
        "zone": "sirarou/n'dali"
    },
    {
        "montant_total_ventes": 12307000.0,
        "zone": "pehunco/koungarou"
    },
    {
        "montant_total_ventes": 129325.0,
        "zone": "ouedo kpossidja kpevi"
    },
    {
        "montant_total_ventes": 304674587.0,
        "zone": "tchaourou"
    },
    {
        "montant_total_ventes": 1476478.0,
        "zone": "beroubay"
    },
    {
        "montant_total_ventes": 3007000.0,
        "zone": " savalou"
    },
    {
        "montant_total_ventes": 15390096.0,
        "zone": "tiganson/banikoara"
    },
    {
        "montant_total_ventes": 14656493.0,
        "zone": "kandi/sam"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "tabarou/n'dali"
    },
    {
        "montant_total_ventes": 110000.0,
        "zone": "sikki"
    },
    {
        "montant_total_ventes": 6738000.0,
        "zone": "toffo "
    },
    {
        "montant_total_ventes": 37678219.0,
        "zone": "gnemasson/ouassa-pehunco"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "gangamou/djougou"
    },
    {
        "montant_total_ventes": 20045108.0,
        "zone": "sinendé "
    },
    {
        "montant_total_ventes": 14882346.0,
        "zone": "sinende/sekere"
    },
    {
        "montant_total_ventes": 12973522.0,
        "zone": "sougou-kpan-trossi"
    },
    {
        "montant_total_ventes": 9850000.0,
        "zone": "zakpota"
    },
    {
        "montant_total_ventes": 817578668.0,
        "zone": "kerou"
    },
    {
        "montant_total_ventes": 1640000.0,
        "zone": "tokibi"
    },
    {
        "montant_total_ventes": 12879062.0,
        "zone": "gandjazounme/lokossa"
    },
    {
        "montant_total_ventes": 24236382.0,
        "zone": "guessou bani"
    },
    {
        "montant_total_ventes": 26362986.0,
        "zone": "nikki/gbaoussikpaa"
    },
    {
        "montant_total_ventes": 5806670.0,
        "zone": "ounet/banikoara"
    },
    {
        "montant_total_ventes": 820000.0,
        "zone": "zou"
    },
    {
        "montant_total_ventes": 14699646.0,
        "zone": "nanerou/sinende"
    },
    {
        "montant_total_ventes": 73392570.0,
        "zone": "bouanri/bembereke"
    },
    {
        "montant_total_ventes": 20182836.0,
        "zone": "boucoumbé/atacora"
    },
    {
        "montant_total_ventes": 40905609.0,
        "zone": "bagou/gogounou"
    },
    {
        "montant_total_ventes": 87219545.0,
        "zone": "cote d'ivoire "
    },
    {
        "montant_total_ventes": 30363413.0,
        "zone": "ina/konou"
    },
    {
        "montant_total_ventes": 931000.0,
        "zone": "cocotomey/ ab-calavi"
    },
    {
        "montant_total_ventes": 1901800.0,
        "zone": "tori bossito"
    },
    {
        "montant_total_ventes": 48561468.0,
        "zone": "toura"
    },
    {
        "montant_total_ventes": 17183426.0,
        "zone": "tanguieta/gorobani"
    },
    {
        "montant_total_ventes": 144674023.0,
        "zone": "kérou"
    },
    {
        "montant_total_ventes": 46104448.0,
        "zone": "allada"
    },
    {
        "montant_total_ventes": 2358727.0,
        "zone": "tchetti"
    },
    {
        "montant_total_ventes": 13232440.0,
        "zone": "badou/alibori"
    },
    {
        "montant_total_ventes": 11634842.0,
        "zone": "goumari/banikoara"
    },
    {
        "montant_total_ventes": 64878326.0,
        "zone": "grand-popo"
    },
    {
        "montant_total_ventes": 110369989.0,
        "zone": "gamia"
    },
    {
        "montant_total_ventes": 6059373.0,
        "zone": "boko/sirarou/n'dali"
    },
    {
        "montant_total_ventes": 51493900.0,
        "zone": "comè"
    },
    {
        "montant_total_ventes": 229377413.0,
        "zone": "abomey"
    },
    {
        "montant_total_ventes": 17806493.0,
        "zone": "sonsoro"
    },
    {
        "montant_total_ventes": 1247042980.0,
        "zone": "banikoara"
    },
    {
        "montant_total_ventes": 35660000.0,
        "zone": "akassato/abomey-calavi"
    },
    {
        "montant_total_ventes": 12000.0,
        "zone": "fidjrossè"
    },
    {
        "montant_total_ventes": 26907000.0,
        "zone": "thio/glazoué"
    },
    {
        "montant_total_ventes": 94374751.0,
        "zone": "sakete"
    },
    {
        "montant_total_ventes": 1785172.0,
        "zone": "angaradebou"
    },
    {
        "montant_total_ventes": 7500000.0,
        "zone": "bohicon "
    },
    {
        "montant_total_ventes": 13400000.0,
        "zone": "atacora / tanguiÉta"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "djougou/zountori"
    },
    {
        "montant_total_ventes": 35200000.0,
        "zone": "godomey"
    },
    {
        "montant_total_ventes": 7929339.0,
        "zone": "abatchang/togo"
    },
    {
        "montant_total_ventes": 38920566.0,
        "zone": "gogounou/badou"
    },
    {
        "montant_total_ventes": 61500000.0,
        "zone": "ze/glo-tangboh"
    },
    {
        "montant_total_ventes": 23989300.0,
        "zone": "agonkame"
    },
    {
        "montant_total_ventes": 41248027.0,
        "zone": "savalou-aga"
    },
    {
        "montant_total_ventes": 143744838.0,
        "zone": "cobly/atacora"
    },
    {
        "montant_total_ventes": 17375139.0,
        "zone": "biro /nikki"
    },
    {
        "montant_total_ventes": 229709389.0,
        "zone": "kétou"
    },
    {
        "montant_total_ventes": 13686394.0,
        "zone": "atacora / pehunco"
    },
    {
        "montant_total_ventes": 33345030.0,
        "zone": "bopa"
    },
    {
        "montant_total_ventes": 50466000.0,
        "zone": "seme podji /porto-novo"
    },
    {
        "montant_total_ventes": 9850000.0,
        "zone": "djoleni/kerou"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kouande/nassoukou"
    },
    {
        "montant_total_ventes": 7200000.0,
        "zone": "mono/bopa"
    },
    {
        "montant_total_ventes": 13450000.0,
        "zone": "adjaha grand-popo"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "calavi/atlantique"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "boukoumbé"
    },
    {
        "montant_total_ventes": 293954394.0,
        "zone": "tanguieta"
    },
    {
        "montant_total_ventes": 13374843.0,
        "zone": "come/agoue"
    },
    {
        "montant_total_ventes": 36921000.0,
        "zone": "bouerou"
    },
    {
        "montant_total_ventes": 1056080241.0,
        "zone": "pehunco"
    },
    {
        "montant_total_ventes": 21000000.0,
        "zone": "n'dali/borgou "
    },
    {
        "montant_total_ventes": 85417016.0,
        "zone": "lalo"
    },
    {
        "montant_total_ventes": 14387000.0,
        "zone": "houeyogbe / mono"
    },
    {
        "montant_total_ventes": 72000.0,
        "zone": "glozoué"
    },
    {
        "montant_total_ventes": 29487900.0,
        "zone": "ketou/plateau"
    },
    {
        "montant_total_ventes": 8500.0,
        "zone": "djinberou / pehunco"
    },
    {
        "montant_total_ventes": 36219767.0,
        "zone": "dassa-zoumè"
    },
    {
        "montant_total_ventes": 49860636.0,
        "zone": "agbangnizoum"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "kouande/bare"
    },
    {
        "montant_total_ventes": 402759430.0,
        "zone": ""
    },
    {
        "montant_total_ventes": 9910000.0,
        "zone": "dangbo"
    },
    {
        "montant_total_ventes": 13043032.0,
        "zone": "kerou/batemin"
    },
    {
        "montant_total_ventes": 594499532.0,
        "zone": "bembèrèkè"
    },
    {
        "montant_total_ventes": 20607000.0,
        "zone": "niaro"
    },
    {
        "montant_total_ventes": 31600356.0,
        "zone": " natitingou/perma"
    },
    {
        "montant_total_ventes": 21017483.0,
        "zone": "boukombe/atacora"
    },
    {
        "montant_total_ventes": 74500.0,
        "zone": "bort"
    },
    {
        "montant_total_ventes": 11007000.0,
        "zone": "tanongou"
    },
    {
        "montant_total_ventes": 12687545.0,
        "zone": "pehunco / atacora"
    },
    {
        "montant_total_ventes": 17918000.0,
        "zone": "ze"
    },
    {
        "montant_total_ventes": 23691000.0,
        "zone": "agbanou/ouidah"
    },
    {
        "montant_total_ventes": 80786852.0,
        "zone": " djougou/soubroukou"
    },
    {
        "montant_total_ventes": 68334469.0,
        "zone": "fonsrame"
    },
    {
        "montant_total_ventes": 13067363.0,
        "zone": "come"
    },
    {
        "montant_total_ventes": 13707124.0,
        "zone": "sougou/gogounou"
    },
    {
        "montant_total_ventes": 37850832.0,
        "zone": "perma/natitingou"
    },
    {
        "montant_total_ventes": 40125.0,
        "zone": "kpomassa/ouidah"
    },
    {
        "montant_total_ventes": 12476000.0,
        "zone": "ifangni/gbaodjo"
    },
    {
        "montant_total_ventes": 34697301.0,
        "zone": "copargo"
    },
    {
        "montant_total_ventes": 19118746.0,
        "zone": "kerou /atacora/kongourou"
    },
    {
        "montant_total_ventes": 9870000.0,
        "zone": "01 bp 884 cotonou -bénin"
    },
    {
        "montant_total_ventes": 3000000.0,
        "zone": "atlantique/tankpe"
    },
    {
        "montant_total_ventes": 17979282.0,
        "zone": "bembèrèkè/kokabo"
    },
    {
        "montant_total_ventes": 4717454.0,
        "zone": "djougou/pélébina"
    },
    {
        "montant_total_ventes": 12300000.0,
        "zone": "natitingou/perma"
    },
    {
        "montant_total_ventes": 72198493.0,
        "zone": "bassila / donga"
    },
    {
        "montant_total_ventes": 833325740.0,
        "zone": "djougou"
    },
    {
        "montant_total_ventes": 91006040.0,
        "zone": "fo bouré/ sinendé"
    },
    {
        "montant_total_ventes": 25688124.0,
        "zone": "saore/bembereke"
    },
    {
        "montant_total_ventes": 108411203.0,
        "zone": "bori"
    },
    {
        "montant_total_ventes": 14611000.0,
        "zone": "zou/cove"
    },
    {
        "montant_total_ventes": 25738345.0,
        "zone": "athieme"
    },
    {
        "montant_total_ventes": 14000.0,
        "zone": "tori "
    },
    {
        "montant_total_ventes": 106571943.0,
        "zone": "gogounou/gounarou"
    },
    {
        "montant_total_ventes": 11000000.0,
        "zone": "klouekanme/adjahome"
    },
    {
        "montant_total_ventes": 47906593.0,
        "zone": "banikoara/goumori"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "sekere/sinende"
    },
    {
        "montant_total_ventes": 13457000.0,
        "zone": "samionta-avlame"
    },
    {
        "montant_total_ventes": 713573919.0,
        "zone": "sinende"
    },
    {
        "montant_total_ventes": 1230800.0,
        "zone": "kpomasse/godonoutin"
    },
    {
        "montant_total_ventes": 235456814.0,
        "zone": "wereke"
    },
    {
        "montant_total_ventes": 18323907.0,
        "zone": "donga/copargo"
    },
    {
        "montant_total_ventes": 0.0,
        "zone": "kerou/gbao"
    },
    {
        "montant_total_ventes": 9901718.0,
        "zone": "kalale/gbekona"
    },
    {
        "montant_total_ventes": 12844062.0,
        "zone": "banikara"
    },
    {
        "montant_total_ventes": 4500000.0,
        "zone": "zagnanado/agonlin-houegbo"
    },
    {
        "montant_total_ventes": 33474734.0,
        "zone": "tokotoko"
    },
    {
        "montant_total_ventes": 16374062.0,
        "zone": "banikora"
    },
    {
        "montant_total_ventes": 19445732.0,
        "zone": "godomey/togoudo"
    },
    {
        "montant_total_ventes": 347515974.0,
        "zone": "ketou"
    },
    {
        "montant_total_ventes": 19114000.0,
        "zone": "toucountouna "
    },
    {
        "montant_total_ventes": 32102886.0,
        "zone": "gninangourou/perere"
    },
    {
        "montant_total_ventes": 21100356.0,
        "zone": "comtcha"
    },
    {
        "montant_total_ventes": 74472001.0,
        "zone": "klouekanme"
    },
    {
        "montant_total_ventes": 0.0,
        "zone": "burkina-faso"
    },
    {
        "montant_total_ventes": 60484390.0,
        "zone": "gnonkourokali/nikki"
    },
    {
        "montant_total_ventes": 295000.0,
        "zone": "ouassa pehunco"
    },
    {
        "montant_total_ventes": 12887215.0,
        "zone": "guessou-sud/bembereke"
    },
    {
        "montant_total_ventes": 1962065077.0,
        "zone": "bembereke"
    },
    {
        "montant_total_ventes": 35168727.0,
        "zone": "azove/couffo"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "tanguieta/atacora "
    },
    {
        "montant_total_ventes": 5475000.0,
        "zone": "aganmanlomey"
    },
    {
        "montant_total_ventes": 185200.0,
        "zone": "agla"
    },
    {
        "montant_total_ventes": 13318746.0,
        "zone": "gourou/sinende"
    },
    {
        "montant_total_ventes": 0.0,
        "zone": "tanguieta/cotiakou"
    },
    {
        "montant_total_ventes": 150954563.0,
        "zone": "ouidah"
    },
    {
        "montant_total_ventes": 10500000.0,
        "zone": "sontou/guininin"
    },
    {
        "montant_total_ventes": 14958565.0,
        "zone": "sinende/alibori"
    },
    {
        "montant_total_ventes": 66861000.0,
        "zone": "sakété"
    },
    {
        "montant_total_ventes": 28456994.0,
        "zone": "garou"
    },
    {
        "montant_total_ventes": 27003890.0,
        "zone": "guinangourou/perere"
    },
    {
        "montant_total_ventes": 181159572.0,
        "zone": "bohicon"
    },
    {
        "montant_total_ventes": 15073522.0,
        "zone": "gogounou/wara"
    },
    {
        "montant_total_ventes": 22396766.0,
        "zone": "ounet"
    }
]
export default datas