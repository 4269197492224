import React from 'react';
import MovingIcon from '@mui/icons-material/Moving';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Typography } from '@mui/material';

// Fonction pour convertir une chaîne avec pourcentage en nombre
const parseValue = (value) => {
  if (typeof value === 'string' && value.includes('%')) {
    // Convertir le pourcentage en nombre
    return parseFloat(value.replace('%', ''));
  }
  return parseFloat(value); // Retourner la valeur numérique si ce n'est pas un pourcentage
};

const FlecheComponent = ({ currentMonthValue, previousMonthValue }) => {
  const currentValue = parseValue(currentMonthValue);
  const previousValue = parseValue(previousMonthValue);

  // Vérifier que les valeurs ne sont pas NaN après conversion
  if (isNaN(currentValue) || isNaN(previousValue)) {
    return <Typography>Valeurs invalides</Typography>;
  }

  const difference = currentValue - previousValue;
  const isIncreasing = currentValue > previousValue;

  const formatDifference = (diff) => {
    return Number.isInteger(diff) ? Math.abs(diff) : Math.abs(diff).toFixed(1);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', color: isIncreasing ? 'green' : 'red'}}>
      <span style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'2rem'}}>
      {isIncreasing ? '+' : '-'} {formatDifference(difference)} 
      </span>
      <span style={{color:'black', marginLeft:8, fontSize:'1.2rem', fontFamily:'Times New Roman'}}>{'ce mois'}</span>
      {isIncreasing ? (
        <MovingIcon style={{ color: 'green', marginLeft:5}} />
      ) : (
        <TrendingDownIcon style={{ color: 'red', marginLeft:5, marginTop:15}} />
      )}
     
    </div>
  );
};

export default FlecheComponent;
