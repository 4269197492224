import React, { useState, useEffect } from 'react';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, IconButton, Typography, Grid, Box, Paper, duration } from '@mui/material';
import { Tab, Tabs, Nav, Row, Col, Container  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomCard from './CustomCard';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import TableComponent from './TableComponent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TypeChart from './TypeChart';
import { prodIndust } from '../api/auth_constant';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const categories = {
  'TRACTEURS': [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
  ],
  'REMORQUES': [
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088'
  ],
  'AUTRES': [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0079'
  ]
};



function ProductionIndustrielleComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tracteurs, setTracteurs] = useState(0);
  const [remorques, setRemorques] = useState(0);
  const [autres, setAutres] = useState(0);
  const [tabValue, setTabValue] = useState('1');
  const [conformityData, setConformityData] = useState([]);
  const [operatorsData, setOperatorsData] = useState({ tracteurs: 0, remorques: 0, autres: 0 });
  const [averageDurations, setAverageDurations] = useState({ tracteurs: 0, remorques: 0, autres: 0}); 
  const [ProdData, setProdData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tracteursDetails, setTracteursDetails] = useState([]);
  const [remorqueDetails, setRemorquesDetails] = useState([]);

  useEffect(() => {
    fetchData(prodIndust);
  }, []);

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      calculateCounts(data);
      const sortedData = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      setProdData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  };
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours} h ${mins} min`;
  };
  const calculateCounts = (data) => {
    let tracteursCount = 0;
    let remorquesCount = 0;
    let autresCount = 0;

    let tracteursOpCount = 0;
    let remorquesOpCount = 0;
    let autresOpCount = 0;

    let tracteursDuration = 0;
    let remorquesDuration = 0;
    let autresDuration = 0;

    let tracteursItems = 0;
    let remorquesItems = 0;
    let autresItems = 0;

    let tracteursConformity = 0;
    let remorquesConformity = 0;
    let autresConformity = 0;

    const tractorTypes = {};
    const remorqueTypes = {};

    data.forEach(item => {
      const isConform = item['Conformité'].toLowerCase() === 'oui';
      const numOperators = parseFloat(item['Nbre_Opérateurs']) || 0;
      const startTime = new Date(`${item.Date_Début.split(' ')[0]}T${item.Heure_Début}`) || 0;
      const endTime = new Date(`${item.Date_Fin.split(' ')[0]}T${item.Heure_Fin}`) || 0;
          let duration;

          if (endTime < startTime) {
            duration = (startTime - endTime) / 60000;
          } else {
            duration = (endTime - startTime) / 60000;
          }
      if (categories.TRACTEURS.includes(item['Réf_Article'])) {
        tracteursCount++;
        tracteursOpCount += numOperators;
        tracteursDuration += duration || 0 ;
        tracteursItems++;
        if (isConform) {
          tracteursConformity++;
        }
        // Track details for each tractor type
        if (!tractorTypes[item['Réf_Article']]) {
          tractorTypes[item['Réf_Article']] = { opCount: 0, duration: 0, items: 0 };
        }
        tractorTypes[item['Réf_Article']].opCount += numOperators;
        tractorTypes[item['Réf_Article']].duration += duration || 0;
        tractorTypes[item['Réf_Article']].items++;

      } else if (categories.REMORQUES.includes(item['Réf_Article'])) {
        remorquesCount++;
        remorquesOpCount += numOperators;
        remorquesDuration += duration || 0;
        remorquesItems++;
        if (isConform) {
          remorquesConformity++;
        }

        if (!remorqueTypes[item['Réf_Article']]) {
          remorqueTypes[item['Réf_Article']] = { opCount: 0, duration: 0, items: 0 };
        }
        remorqueTypes[item['Réf_Article']].opCount += numOperators;
        remorqueTypes[item['Réf_Article']].duration += duration || 0;
        remorqueTypes[item['Réf_Article']].items++;

      } else if (categories.AUTRES.includes(item['Réf_Article'])) {
        autresCount++;
        autresOpCount += numOperators;
        autresDuration += duration || 0;
        autresItems++;
        if (isConform) {
          autresConformity++;
        }
      }
    });

    setTracteurs(tracteursCount);
    setRemorques(remorquesCount);
    setAutres(autresCount);

    setOperatorsData({
      tracteurs: tracteursItems ? (tracteursOpCount / tracteursItems).toFixed(2) : '0.00',
      remorques: remorquesItems ? (remorquesOpCount / remorquesItems).toFixed(2) : '0.00',
      autres: autresItems ? (autresOpCount / autresItems).toFixed(2) : '0.00'
    });

    setAverageDurations({
      tracteurs: tracteursItems ? formatDuration(tracteursDuration / tracteursItems) : '0:00',
      remorques: remorquesItems ? formatDuration(remorquesDuration / remorquesItems) : '0:00',
      autres: autresItems ? formatDuration(autresDuration / autresItems) : '0:00'
    });
  

    // Prepare details for each tractor type
    const tractorDetails = Object.keys(tractorTypes).map(type => ({
      type,
      avgDuration: formatDuration(tractorTypes[type].duration / tractorTypes[type].items),
      avgOperators: (tractorTypes[type].opCount / tractorTypes[type].items).toFixed(2)
    }));

    const remorqueDetails = Object.keys(remorqueTypes).map(typeRe => ({
      typeRe,
      avgDurationRe: formatDuration(remorqueTypes[typeRe].duration / remorqueTypes[typeRe].items),
      avgOperatorsRe: (remorqueTypes[typeRe].opCount / remorqueTypes[typeRe].items).toFixed(2)
    }));

    setTracteursDetails(tractorDetails);
    setRemorquesDetails(remorqueDetails);

    setConformityData([
      { id: 1, label: 'Tracteurs', value: tracteursConformity },
      { id: 2, label: 'Remorques', value: remorquesConformity },
      { id: 3, label: 'Autres', value: autresConformity }
    ]);
  };

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const columns = [
    { id: 'Chef_D\'équipe', label: 'Chef D\'équipe', align: 'right' },
    { id: 'Conformité', label: 'Conformité', align: 'right' },
    { id: 'Date', label: 'Date', align: 'right' },
    { id: 'Date_Début', label: 'Date Début', align: 'right' },
    { id: 'Date_Fin', label: 'Date Fin', align: 'right' },
    { id: 'Durée', label: 'Durée', align: 'right' },
    { id: 'Désignation_Article', label: 'Désignation Article', align: 'right' },
    { id: 'Fiche_de_Production', label: 'Fiche de Production', align: 'right' },
    { id: 'Heure_Début', label: 'Heure Début', align: 'right' },
    { id: 'Heure_Fin', label: 'Heure Fin', align: 'right' },
    { id: 'Nbre_Opérateurs', label: 'Nbre Opérateurs', align: 'right' },
    { id: 'Numéro_Inconu', label: 'Numéro Inconu', align: 'right' },
    { id: 'Numéro_chassis', label: 'Numéro chassis', align: 'right' },
    { id: 'Observations', label: 'Observations', align: 'right' },
    { id: 'Ordre', label: 'Ordre', align: 'right' },
    { id: 'Réf_Article', label: 'Réf Article', align: 'right' },
    { id: 'Sem', label: 'Sem', align: 'right' },
    { id: 'Site', label: 'Site', align: 'right' },
    { id: 'Série_lot/Final', label: 'Série lot/Final', align: 'right' },
    { id: '_id', label: 'ID', align: 'right' },
];


  return (
    <Box sx={{ display: 'flex' }}>
      <SideBar open={sidebarOpen} onClose={handleDrawerClose} />
      <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
        <Toolbar />
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}  justifyContent="space-between"  alignItems={{ xs: 'flex-start', sm: 'center' }}  mb={3}>
            <CalendarButton selectedDate={selectedDate} handleDateChange={handleDateChange} />
            <Box mt={{ xs: 2, sm: 0 }} ml={{ sm: 2 }} width={{ xs: '100%', sm: 'auto' }}>
              <ButtonComponent barChartData={null} />
            </Box>
          </Box>
          <TabContext value={tabValue}>
          <Box 
            width="100%" 
            mx="auto" 
            sx={{ 
              borderBottom: { xs: 'none', sm: 3 }, 
              borderColor:  { xs: 'transparent', sm: 'divider', md: 'divider' },
              overflowX: 'auto',
               // Gestion du débordement horizontal
            }}
          >
            <Nav 
              variant="tabs" 
              defaultActiveKey="1" 
              className="flex-column flex-sm-row justify-content-start"
               // Classes Bootstrap pour responsivité
            >
              {[
                'Assemblage',
                'Mise à disposition du client',
                'Opération',
                'Pannes et fin de vie',
                'Prédictions',
              ].map((label, index) => (
                <Nav.Item key={index} className="flex-fill"> 
                  <Nav.Link
                    eventKey={`${index + 1}`}
                    active={tabValue === `${index + 1}`}
                    onClick={() => handleTabChange(null, `${index + 1}`)}
                    style={{
                      color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                      fontSize: '1.2rem',
                      textTransform: 'none',
                      whiteSpace: 'nowrap', // Empêche le retour à la ligne
                    }}
                  >
                    {label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Box>

          <TabPanel value="1">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomCard title="Tracteurs" value={`${tracteurs}`} tracteurs color="#ffff" icon={<AgricultureIcon />}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard title="Remorques" value={`${remorques}`} color="#ffff" icon={<RvHookupIcon />}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard title="Autres" value={`${autres}`} color="#ffff" icon={<AddCircleOutlineIcon />}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={4} className="d-flex flex-column align-items-center">
            <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Indice de Conformité</Typography>
              <Paper elevation={16} sx={{ 
                      padding: 2, 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      width: '100%', 
                      maxWidth: 400, 
                      height: 400,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
                      borderRadius: '4px', 
                      backgroundColor: 'white' // Couleur de fond
                    }}>
                <TypeChart data={conformityData} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Engin</TableCell>
                        <TableCell align="right">Durée moyenne</TableCell>
                        <TableCell align="right">Nombre d'opérateur moyen</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Ligne pour la catégorie "Tracteurs" */}
                      <TableRow>
                        <TableCell colSpan={3} sx={{ fontWeight: 'bold', backgroundColor: '#02834A' }}>
                          Tracteurs
                        </TableCell>
                      </TableRow>
                      {tracteursDetails.map((row) => (
                        <TableRow key={row.type}>
                          <TableCell>{row.type}</TableCell>
                          <TableCell align="right">{row.avgDuration}</TableCell>
                          <TableCell align="right">{row.avgOperators}</TableCell>
                        </TableRow>
                      ))}

                      {/* Ligne pour la catégorie "Remorques" */}
                      <TableRow>
                        <TableCell colSpan={3} sx={{ fontWeight: 'bold', backgroundColor: '#02834A' }}>
                          Remorques
                        </TableCell>
                      </TableRow>
                      {remorqueDetails.map((row) => (
                        <TableRow key={row.typeRe}>
                          <TableCell>{row.typeRe}</TableCell>
                          <TableCell align="right">{row.avgDurationRe}</TableCell>
                          <TableCell align="right">{row.avgOperatorsRe}</TableCell>
                        </TableRow>
                      ))}

                      {/* Ligne pour les moyennes des remorques */}
                      <TableRow>
                        <TableCell>Remorques</TableCell>
                        <TableCell align="right">{averageDurations.remorques}</TableCell>
                        <TableCell align="right">{operatorsData.remorques}</TableCell>
                      </TableRow>

                      {/* Ligne pour la catégorie "Autres" */}
                      <TableRow>
                        <TableCell colSpan={3} sx={{ fontWeight: 'bold', backgroundColor: '#02834A' }}>
                          Autres
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Autres</TableCell>
                        <TableCell align="right">{averageDurations.autres}</TableCell>
                        <TableCell align="right">{operatorsData.autres}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
          <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', 
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '600px',   // Small
                    md: '800px',   // Medium
                    lg: '1000px',  // Large
                    xl: '1200px',  // Extra Large
                  },
                }}
              >
                <TableComponent
                  data={ProdData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>
        </TabPanel>
          <TabPanel value="2">
            <Typography variant="h6">Hello from Intervention</Typography>
          </TabPanel>
          <TabPanel value="3">
            <Typography variant="h6">Hello from Retex</Typography>
          </TabPanel>
          <TabPanel value="4">
            <Typography variant="h6">Hello</Typography>
          </TabPanel>
          <TabPanel value="5">
            <Typography variant="h6">prédictions</Typography>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Mecanicien'])(ProductionIndustrielleComponent);