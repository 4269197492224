export const clients=[
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "RENAUD  Eric",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "RENAUD  Eric",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL001",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL001",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'RENAUD  Eric'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL001'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL001'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "98",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    },
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "NGUYENQUOC  Linh",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "NGUYENQUOC  Linh",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL002",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL002",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'NGUYENQUOC  Linh'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL002'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL002'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "99",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    },
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "AKIN  Christelle",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "AKIN  Christelle",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL003",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL003",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'AKIN  Christelle'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL003'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL003'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "100",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    },
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "BALCAEN  Frédéric",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "BALCAEN  Frédéric",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL004",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL004",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'BALCAEN  Fr\\xe9d\\xe9ric'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL004'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL004'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "101",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    },
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "TCHIBOZO  Franck",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "TCHIBOZO  Franck",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL005",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL005",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'TCHIBOZO  Franck'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL005'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL005'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "102",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    },
    {
        "BT_Num": "0",
        "CAL_No": "0",
        "CA_Num": "None",
        "CA_NumIFRS": "None",
        "CG_NumPrinc": "42200000",
        "CO_No": "0",
        "CT_Adresse": "",
        "CT_AnnulationCR": "0",
        "CT_Ape": "",
        "CT_Assurance": "0.000000",
        "CT_BLFact": "0",
        "CT_BonAPayer": "0",
        "CT_Classement": "ADOGONY Kokougan",
        "CT_CodePostal": "",
        "CT_CodeRegion": "",
        "CT_Coface": "",
        "CT_Commentaire": "",
        "CT_Complement": "",
        "CT_Contact": "",
        "CT_ControlEnc": "0",
        "CT_DateFermeDebut": "1753-01-01 00:00:00",
        "CT_DateFermeFin": "1753-01-01 00:00:00",
        "CT_DateMAJ": "1753-01-01 00:00:00",
        "CT_DelaiAppro": "0",
        "CT_DelaiTransport": "0",
        "CT_DeliveryDay01": "0",
        "CT_DeliveryDay02": "0",
        "CT_DeliveryDay03": "0",
        "CT_DeliveryDay04": "0",
        "CT_DeliveryDay05": "0",
        "CT_DeliveryDay06": "0",
        "CT_DeliveryDay07": "0",
        "CT_EMail": "",
        "CT_EchangeCR": "0",
        "CT_EchangeRappro": "0",
        "CT_EdiCode": "",
        "CT_EdiCodeSage": "",
        "CT_EdiCodeType": "0",
        "CT_Encours": "0.000000",
        "CT_ExclureTrait": "0",
        "CT_Facebook": "",
        "CT_Facture": "1",
        "CT_FactureElec": "0",
        "CT_GDPR": "0",
        "CT_Identifiant": "",
        "CT_Intitule": "ADOGONY Kokougan",
        "CT_Langue": "0",
        "CT_LangueISO2": "",
        "CT_Lettrage": "1",
        "CT_LinkedIn": "",
        "CT_LivrPartielle": "0",
        "CT_NotPenal": "0",
        "CT_NotRappel": "0",
        "CT_Num": "SL006",
        "CT_NumCentrale": "None",
        "CT_NumPayeur": "SL006",
        "CT_OrderDay01": "0",
        "CT_OrderDay02": "0",
        "CT_OrderDay03": "0",
        "CT_OrderDay04": "0",
        "CT_OrderDay05": "0",
        "CT_OrderDay06": "0",
        "CT_OrderDay07": "0",
        "CT_Pays": "",
        "CT_PrioriteLivr": "0",
        "CT_ProfilSoc": "0",
        "CT_Prospect": "0",
        "CT_Qualite": "",
        "CT_Raccourci": "",
        "CT_RepresentInt": "",
        "CT_RepresentNIF": "",
        "CT_Saut": "1",
        "CT_Siret": "",
        "CT_Site": "",
        "CT_Sommeil": "0",
        "CT_Statistique01": "",
        "CT_Statistique02": "",
        "CT_Statistique03": "",
        "CT_Statistique04": "",
        "CT_Statistique05": "",
        "CT_Statistique06": "",
        "CT_Statistique07": "",
        "CT_Statistique08": "",
        "CT_Statistique09": "",
        "CT_Statistique10": "",
        "CT_StatutContrat": "0",
        "CT_Surveillance": "0",
        "CT_SvCA": "0.000000",
        "CT_SvCotation": "",
        "CT_SvDateBilan": "1753-01-01 00:00:00",
        "CT_SvDateCreate": "1753-01-01 00:00:00",
        "CT_SvDateIncid": "1753-01-01 00:00:00",
        "CT_SvDateMaj": "1753-01-01 00:00:00",
        "CT_SvEffectif": "",
        "CT_SvFormeJuri": "",
        "CT_SvIncident": "0",
        "CT_SvNbMoisBilan": "0",
        "CT_SvObjetMaj": "",
        "CT_SvPrivil": "0",
        "CT_SvRegul": "",
        "CT_SvResultat": "0.000000",
        "CT_Taux01": "0.000000",
        "CT_Taux02": "0.000000",
        "CT_Taux03": "0.000000",
        "CT_Taux04": "0.000000",
        "CT_Telecopie": "",
        "CT_Telephone": "",
        "CT_Type": "2",
        "CT_TypeNIF": "0",
        "CT_ValidEch": "0",
        "CT_Ville": "",
        "DE_No": "0",
        "EB_No": "0",
        "MR_No": "0",
        "N_Analytique": "0",
        "N_AnalytiqueIFRS": "0",
        "N_CatCompta": "1",
        "N_CatTarif": "1",
        "N_Condition": "1",
        "N_Devise": "0",
        "N_Expedition": "1",
        "N_Period": "1",
        "N_Risque": "1",
        "PI_NoEchange": "0",
        "cbCAL_No": "nan",
        "cbCA_Num": "b''",
        "cbCG_NumPrinc": "b'42200000'",
        "cbCO_No": "None",
        "cbCT_Classement": "b'ADOGONY Kokougan'",
        "cbCT_CodePostal": "b''",
        "cbCT_Num": "b'SL006'",
        "cbCT_NumCentrale": "b''",
        "cbCT_NumPayeur": "b'SL006'",
        "cbCT_Raccourci": "b''",
        "cbCreateur": "ERP1",
        "cbCreation": "2022-02-04 13:05:33",
        "cbCreationUser": "5E6A3B15-C10C-42F0-91C0-C23D7C499E32",
        "cbDE_No": "None",
        "cbEB_No": "None",
        "cbFlag": "0",
        "cbMR_No": "nan",
        "cbMarq": "103",
        "cbModification": "2022-03-01 12:25:41",
        "cbN_Analytique": "nan",
        "cbN_AnalytiqueIFRS": "None",
        "cbPI_NoEchange": "None",
        "cbProt": "0",
        "cbReplication": "0"
    }
]