import { useEffect, useRef, useState } from "react";
import * as topojson from "topojson-client";
import * as d3 from "d3";
import colors from "./colors";
import datas from "./data";
import { clients } from "./client";
import { commercials } from "./commercial";
import { baseUrl } from "../../../api/auth_constant";
export function MapChart() {
    const [state, setState] = useState({ country: null,totalVente:[],totalClient:[]});
    const [svgRef1,svgRef2] = [useRef(null),useRef(null)];
    const height=850;
    let totalVente=clients.filter((client,index)=>index<commercials.length?client.CT_Num===commercials[index].CT_Num:{});
    let totalClient=Array.from(new Set(totalVente.map(item=>item.CT_Num))).map(ct_num=>totalVente.find(item=>item.CT_Num===ct_num));
    const jsonToData = async (data) => {
      try {
        const responses= await Promise.all([
          d3.json(String(data.countries)),
          d3.json(String(`${baseUrl}commercial/liste_vente`)),
          d3.json(String(`${baseUrl}client `))
        ]);
        console.log(responses)
        const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
        setState(
            { 
                country: countriesGeoJSON,
                totalVente:responses[1].filter((client,index)=>index<commercials.length?responses[2].CT_Num===commercials[index].CT_Num:{})
            }
        );

        setState(
            {
                totalClient:Array.from(new Set(state.totalVente.map(item=>item.CT_Num))).map(ct_num=>state.totalVente.find(item=>item.CT_Num===ct_num))
            }
        )
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    const fetchData = async () => {
      await jsonToData({
        countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
      });
    };
  
    useEffect(() => {
      fetchData();
    },[]);
  
    const showMap=(svgRef,tooltipId,label,datas)=>{
        if (state.country && svgRef.current) {
        
            const svg = d3.select(svgRef.current)
            .attr("viewBox", [0, 0, height/2])
            .attr("height", height)
            .on("click", reset);
      
            const tooltip=d3.select(tooltipId).style('pointer-events','none').style("visibility","hidden")
      
            const datacolor = [
              ">=1",
              "10-70",
              "70-100",
              ">100"
            ];
            const xAxisScale = d3.scaleBand()
            .domain(datacolor)
            .range([30, height/3])
            .padding(0.04);
      
      
      
            const projection = d3.geoProjection(function(x, y) {
              return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
            }).scale(5800).translate([-20,1370]);
      
            const path = d3.geoPath().projection(projection);
            
            const zoom = d3.zoom()
              .scaleExtent([1, 8])
              .on("zoom", zoomed);
      
            svg.call(zoom);
      
            svg.selectAll("*").remove();
            const g = svg.append("g")
            .attr("fill", "#444");
      
            g.selectAll("path")
              .data(state.country.features)
              .join('path')
              .attr('fill', function(d,i){
                if(datas.find(element=>element["CT_Adresse"].includes(String(d.properties.name).toLowerCase()))){
                  const datafilter=datas.filter(element=>element.zone.includes(String(d.properties.name).toLowerCase())?element:null)
                  let somme = datafilter.length;
                  if(somme>=0 && somme<10)
                    {
                      return colors[0]
                    }
                  else if(somme>10&& somme<70)
                    {
                      return colors[1]
                    }
                    else if(somme>70 && somme<100)
                      {
                        return colors[2]
                      }
                      else if(somme>=100){
                        return colors[3]
                      }
      
                }
                else{
                  return "#fff"
                }
              }
              )
              .attr('d', path)
              .attr('title',(d)=>d.properties?.name)
              .attr('stroke', 'gray')
              .attr('cursor',"pointer")
              .on("mouseover",(event,d)=>{
                  const datafilter=datas.filter(element=>element.zone.includes(String(d.properties.name).toLowerCase())?element:null)
                  console.log(datafilter)
                  let somme = datafilter.length;
                  tooltip
                  .style("top",`${event.pageY}px`)
                  .style("visibility","visible")
                  .html(`<em>
                  <strong style="text-transform:capitalize">ville: </strong><span style="text-transform:capitalize">${d.properties.name.toLowerCase()}</span><br/>
                  <strong style="text-transform:capitalize">${label} </strong>${somme}<br/>
                  </em>`)
              })
              .on("mouseout",(event,i)=>{
                  tooltip.html("").style("visibility","hidden").style("top",0)
              })
              .on("click", clicked);
              const xAxis = svg.append("g")
              .attr("transform", `translate(0,30)`)
              .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
              .selectAll("text")
              .style("text-anchor", "middle")
            svg.selectAll(".bar")
              .data(datacolor)
              .enter().append("rect")
              .attr("class", "bar")
              .attr("x", d => xAxisScale(d))
              .attr("width", xAxisScale.bandwidth())
              .attr("y", 10)
              .attr("height", 20)
              .attr("fill", d => {
                switch (d) {
                  case ">1":
                    return colors[0];
                  case "10-70":
                    return colors[1];
                  case "70-100":
                    return colors[2];
                  case ">100":
                    return colors[3];
                }
              });
              
            function zoomed(event) {
              g.attr("transform", event.transform);
            }
      
            function clicked(event, d) {
              const [[x0, y0], [x1, y1]] = path.bounds(d);
              event.stopPropagation();
              svg.transition().duration(750).call(
                zoom.transform,
                d3.zoomIdentity
                  .translate(0, height / 2)
                  .scale(Math.min(8, 0.9 / Math.max((x1 - x0) , (y1 - y0))))
                  .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
                d3.pointer(event, svg.node())
              );
            }
      
            function reset() {
              g.transition().style("fill", null);
              svg.transition().duration(750).call(
                zoom.transform,
                d3.zoomIdentity,
                d3.zoomTransform(svg.node()).invert([0, height / 2])
              );
            }
      
          }
    }

    useEffect(() => {
      showMap(svgRef1,'#tooltip1',"Total de Vente :",state.totalVente);
      showMap(svgRef2,'#tooltip2',"Total de client :",state.totalClient);
    }, [state]);
  
    return (
      <>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <svg width={window.innerWidth/2} ref={svgRef1}/>
            <div id="tooltip1"></div>
            </div>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <svg width={window.innerWidth/2} ref={svgRef2}/>
            <div id="tooltip2"></div>
            </div>
        </div>
      </>
    );
  }