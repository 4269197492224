import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { Paper, Grid, Modal, Box, Toolbar, Typography, TextField } from '@mui/material';
import { statistiqueUrl, prodIndust, panne, tractor} from '../../api/auth_constant';
import ButtonComponent from '../ButtonComponent';
import CalendarButton from '../CalendarButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import FlecheComponent from '../FlecheComponent';
import { Nav  } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 
   
const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },
];
const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];
const categories = {
  'TRACTEURS': [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
  ],
  'AUTRES': [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088',
    'PSF0079'
  ]
};

function FormationComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const [totalProduit, setTotalProduit] = useState(0);
  const [totalPanne, setTotalPanne] = useState(null);
  const [total_tracteur, setTotalTracteur] = useState(0);
  const [totaltracteurDispo, setTotal_T] = useState(0);
  const [conformite, setconforme] = useState(0);
  const [cloture, setcloture] = useState(0);
  const [dataPuissance, setDataPuissance] = useState([]);
  const [dataMarque, setdataMarque] = useState([]);
  const [noncloture, setnoncloture] = useState(0);
  const [trackMark, settrackMark] = useState(0);
  const [trackConf, settrackConf] = useState(0);
  const [trackConf2, settrackConf2] = useState(0);
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const navigate = useNavigate();
  const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
  const [chartData, setChartData] = useState({ labels: [], data: [] });
  const [chartDataPannes, setChartDataPannes] = useState({ labels: [], data: [] });
  const [barData, setBarData] = useState([]); // État pour les données du graphique à barres
  const [barData2, setBarData2] = useState([]); // État pour les données du graphique à barres
  const [chartDataPannesByMark, setChartDataPannesByMark] = useState([]);
  const [tracteurPuissance, setTracteurPuissance] = useState([]);
  const [totalTractors, setTotalTractors] = useState(0);
  const [avgTauxConformite, setAvgTauxConformite] = useState(0); // Nouvel état pour la moyenne
  const [articlesParCategorie, setArticlesParCategorie] = useState(0); 
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleVagueChange = (event, newValue) => {
    setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  function calculerTauxConformite(data) {
    const total = data.length;
    const conformes = data.filter(item => item['Conformité']!==null && item['Conformité']!==undefined && item['Conformité'].toLowerCase() === 'oui').length;
    return Math.trunc((conformes / total) * 100);
  }
  function calculerTauxConformite2(data) {
    const total = data.length;
    const conformes = data.filter(item => item['Conformité']!==null && item['Conformité']!==undefined && item['Conformité'].toLowerCase() === 'oui').length;
    return Math.trunc((conformes / total) * 100);
  }

  function calculerDossierCloture(data) {
    const dossierCloture = data.filter(item => item['Dossier clôturé'].toLowerCase() === 'oui').length;
    return dossierCloture ;
  }

  function calculernonDossierCloture(data) {
    const dossierCloture = data.filter(item => item['Dossier clôturé'].toLowerCase() === 'non').length;
    return dossierCloture ;
  }
  const countFormationsByMonth = (data) => {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    
    // Initialiser le tableau des mois avec des zéros
    const monthlyCounts = new Array(12).fill(0);
  
    // Parcourir les données
    data.forEach(item => {
      // Convertir la date au format Date
      const date = new Date(item.Date);
      
      // Obtenir le mois et l'année de la date
      const month = date.getMonth(); // 0 pour Janvier, 11 pour Décembre
      const year = date.getFullYear();
  
      // Vérifier si l'année correspond à l'année sélectionnée
      if (year === Number(selectedAnnee)) {
        // Incrémenter le compteur du mois correspondant
        monthlyCounts[month]++;
      }
    });
  
    return { labels: months, data: monthlyCounts };
  };
  
  const regrouperTracteursParNomAppareil = (data) => {
    return data.reduce((acc, panneItem) => {
      const designationTracteur = panneItem['Désignation_tracteur']; 
  
      if (!acc[designationTracteur]) {
        acc[designationTracteur] = {
          name: designationTracteur,
          count: 0,
        };
      }
      acc[designationTracteur].count += 1; 
  
      return acc;
    }, {});
  };
  
  const countProductionsByCategory = (data) => {
    // Initialisation des compteurs pour chaque catégorie
    const counts = {
        TRACTEURS: {},
        AUTRES: {}
    };

    // Stockage des désignations d'articles pour chaque référence
    const articleDesignations = {};

    // Parcourir les données pour compter les articles par catégorie
    data.forEach(item => {
        const refArticle = item['Réf_Article'];
        const designationArticle = item['Désignation_Article'];

        // Stockage de la désignation d'article pour chaque référence
        if (!articleDesignations[refArticle]) {
            articleDesignations[refArticle] = designationArticle;
        }

        // Compteur pour chaque catégorie
        if (categories.TRACTEURS.includes(refArticle)) {
            counts.TRACTEURS[refArticle] = (counts.TRACTEURS[refArticle] || 0) + 1;
        } 
        else if (categories.AUTRES.includes(refArticle)) {
            counts.AUTRES[refArticle] = (counts.AUTRES[refArticle] || 0) + 1;
        }
    });

    // Convertir les compteurs en format attendu pour les graphiques
    const formatData = (categoryCounts) => {
        // Convertir les compteurs en tableau d'objets
        const dataArray = Object.keys(categoryCounts).map(refArticle => ({
            name: articleDesignations[refArticle] || refArticle, // Utilise la désignation ou la référence si non trouvée
            ProductionParArticle: categoryCounts[refArticle]
        }));

        // Trier les données par ProductionParArticle en ordre décroissant
        return dataArray.sort((a, b) => b.ProductionParArticle - a.ProductionParArticle);
    };

    // Calculer le nombre total pour chaque catégorie
    const totalTRACTEURS = Object.values(counts.TRACTEURS).reduce((acc, count) => acc + count, 0);
    const totalAUTRES = Object.values(counts.AUTRES).reduce((acc, count) => acc + count, 0);

    return {
        TRACTEURS: formatData(counts.TRACTEURS),
        AUTRES: formatData(counts.AUTRES),
        totalTRACTEURS,  // Nombre total de tracteurs
        totalAUTRES      // Nombre total d'articles dans la catégorie AUTRES
    };
};

function transformDataByPuissance(data) {
  let totalTracteurs = 0; // Initialiser le total des tracteurs
  const result = data.reduce((acc, item) => {
    if (item.Puissance !== null) {
      const puissance = item.Puissance;

      // Incrémenter le total des tracteurs
      totalTracteurs++;

      if (acc[puissance]) {
        acc[puissance].ProductionParArticle += 1;
      } else {
        acc[puissance] = {
          name: puissance,
          ProductionParArticle: 1,
        };
      }
    }
    return acc;
  }, {});

  // Correction de la construction de Dataresult
  const Dataresult = Object.keys(result).map(puissance => {
    return {
      name: puissance, // Utilise la désignation de l'article
      ProductionParArticle: result[puissance].ProductionParArticle, // Référence à la valeur correcte
    };
  });

  // Tri des résultats par ProductionParArticle de manière décroissante
  Dataresult.sort((a, b) => b.ProductionParArticle - a.ProductionParArticle);

  return { Dataresult, totalTracteurs }; // Retourner à la fois les données et le total
}


function transformDataByMarque(data) {
  let total_Tractors = 0;
  const result = data.reduce((acc, item) => {
    if (item.Marque !== null) {
      const marque = item.Marque;
      total_Tractors++;

      if (acc[marque]) {
        acc[marque].ProductionParArticle += 1;
      } else {
        acc[marque] = {
          name: marque,
          ProductionParArticle: 1,
        };
      }
    }
    return acc;
  }, {});

  // Correction de la construction de Dataresult
  const DataresultMarque = Object.keys(result).map(marque => {
    return {
      name: marque, // Utilise la désignation de l'article
      ProductionParArticle: result[marque].ProductionParArticle, // Référence à la valeur correcte
    };
  });

  // Tri des résultats par ProductionParArticle de manière décroissante
  DataresultMarque.sort((a, b) => b.ProductionParArticle - a.ProductionParArticle);
  return { DataresultMarque, total_Tractors };
}
  const TrackData = (data) => {
    // Initialiser un objet pour stocker les compteurs pour chaque marque de tracteur
    const counts = {};
  
    // Parcourir les données pour compter les tracteurs par marque
    data.forEach(item => {
      const tractorMark = item.tractorMark; // Récupérer la marque du tracteur
  
      if (tractorMark) {
        counts[tractorMark] = (counts[tractorMark] || 0) + 1; // Incrémenter le compteur pour chaque marque
      }
    });
  
    // Convertir les compteurs en format attendu pour les graphiques
    const formattedData = Object.keys(counts).map(tractorMark => ({
      tractorMark: tractorMark, // La marque du tracteur
      NombreParMarque: counts[tractorMark] // Le nombre de tracteurs pour cette marque
    }))
    
    const sortedData = formattedData.sort((a, b) => b.NombreParMarque - a.NombreParMarque);

    return sortedData;
  }; 

  
  function Taux_conformite_par_tracteurs(data) {
    // Filtrage des tracteurs ayant une puissance définie
    const tracteursData = data.filter(item => item['Puissance']);
  
    // Variables pour calculer la moyenne globale
    let totalConformes = 0;
    let totalTracteurs = 0;
  
    // Regroupement par puissance
    const tracteursGroupes = tracteursData.reduce((acc, item) => {
      const puissance = item['Puissance'];
      if (!acc[puissance]) {
        acc[puissance] = {
          puissance: puissance, // Stocke la puissance
          total: 0, // Nombre total d'éléments
          conformes: 0 // Nombre d'éléments conformes
        };
      }
  
      // Incrémenter le total des tracteurs pour cette puissance
      acc[puissance].total++;
      totalTracteurs++; // Incrémenter le total général des tracteurs
  
      // Vérification de la conformité ('oui')
      if (item['Conformité'] && item['Conformité'].toLowerCase() === 'oui') {
        acc[puissance].conformes++;
        totalConformes++; // Incrémenter le total des tracteurs conformes
      }
      return acc;
    }, {});
  
    // Calcul du taux de conformité pour chaque puissance
    const tauxParPuissance = Object.keys(tracteursGroupes).map(puissance => {
      const groupe = tracteursGroupes[puissance];
      const tauxConformite = (groupe.conformes / groupe.total) * 100; // Calcul du pourcentage
      return {
        name: puissance,
        tauxConformite: Math.trunc(tauxConformite), // Arrondir à 2 décimales
        tauxConformiteLabel: `${Math.trunc(tauxConformite)}%`
      };
    });
  
    // Tri par taux de conformité décroissant
    tauxParPuissance.sort((a, b) => b.tauxConformite - a.tauxConformite);
  
    // Calcul de la moyenne des conformités globales
    const moyenneConformite = (totalConformes / totalTracteurs) * 100;
  
    return {
      tauxParPuissance, // Les taux par puissance
      moyenneConformite: Math.trunc(moyenneConformite) // La moyenne arrondie à l'entier
    };
  }
  

function Taux_conformite_par_marques(data) {
  // Filtrage des tracteurs ayant une puissance définie
  const tracteursData = data.filter(item => item['Marque']);

  // Regroupement par puissance
  const tracteursGroupes = tracteursData.reduce((acc, item) => {
      const marque = item['Marque'];
      if (!acc[marque]) {
          acc[marque] = {
              marque: marque, // Stocke la marque
              total: 0, // Nombre total d'éléments
              conformes: 0 // Nombre d'éléments conformes
          };
      }
      
      // Incrémenter le total des tracteurs pour cette marque
      acc[marque].total++;
      
      // Vérification de la conformité ('oui')
      if (item['Conformité'] && item['Conformité'].toLowerCase() === 'oui') {
          acc[marque].conformes++;
      }
      return acc;
  }, {});

  // Calcul du taux de conformité pour chaque marque
  const tauxParMarque = Object.keys(tracteursGroupes).map(marque => {
      const groupe = tracteursGroupes[marque];
      const tauxConformite = (groupe.conformes / groupe.total) * 100; // Calcul du pourcentage
      return {
          name: marque,
          tauxConformite: Math.trunc(tauxConformite), // Arrondir à 2 décimales
          tauxConformiteLabel: `${Math.trunc(tauxConformite)}%`
      };
  });

  // Tri par taux de conformité décroissant
  tauxParMarque.sort((a, b) => b.tauxConformite - a.tauxConformite);

  return tauxParMarque;
}

const regrouperArticlesParCategorie = (data) => {
  // Regroupement des articles en sous-catégories
  const regroupements = {
      Charrues: {},
      Remorques: {},
      Cultivateurs: {},
      Semoirs: {},
      Autres: {}
  };

  data.forEach(item => {
      const refArticle = item['Réf_Article'];
      const designationArticle = item['Désignation_Article'];
      let categorie = 'Autres'; // Valeur par défaut

      // Vérifiez si l'article appartient à la catégorie AUTRES
      if (categories.AUTRES.includes(refArticle)) {
          // Déterminez la sous-catégorie basée sur la désignation de l'article
          if (designationArticle.includes('Charrue')) {
              categorie = 'Charrues';
          } else if (designationArticle.includes('Remorque')) {
              categorie = 'Remorques';
          } else if (designationArticle.includes('Cultivateur')) {
              categorie = 'Cultivateurs';
          } else if (designationArticle.includes('Semoir')) {
              categorie = 'Semoirs';
          }

          // Comptage des articles dans la sous-catégorie appropriée
          if (!regroupements[categorie][designationArticle]) {
              regroupements[categorie][designationArticle] = 0;
          }
          regroupements[categorie][designationArticle] += 1;
      }
  });

  // Convertir les résultats en format utilisable pour le graphique
  const resultats = Object.keys(regroupements).map(categorie => ({
      name: categorie,
      count: Object.values(regroupements[categorie]).reduce((acc, val) => acc + val, 0)
  }));

  return resultats;
};

  useEffect(() => {
    fetchData(statistiqueUrl);
    fetchData2(prodIndust);
    fetchData3(panne);
    fetchData4(tractor);
    fetchData6(panne);
    fetchData7(prodIndust);
  }, []);
  
  useEffect(() => {
    async function loadData() {
      try {
        await fetchData66();
      } catch (error) {
        console.error('Error loading data:', error);
        setBarData2([]); 
      }
    }

    loadData();
  }, []);
    useEffect(() => {
    async function loadData() {
        const result = await fetchData5();
        if (result && result.chartDataPannesByMark) {
            setChartDataPannesByMark(result.chartDataPannesByMark);
        } else {
            console.error('Data structure not as expected:', result);
            setChartDataPannesByMark([]); // Définir un état par défaut vide en cas d'erreur
        }
    }
    loadData();
}, []);
    useEffect(() => {
      async function loadData() {
        try {
          await fetchData77();
        } catch (error) {
          console.error('Error loading data:', error);
          setBarData([]); 
        }
      }

      loadData();
    }, []);
  

  useEffect(() => {
    if (selectedAnnee) {
      fetchData2(prodIndust);
    }
  }, [selectedAnnee]);
  
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotal_T(data["Total"]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData2 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotalProduit(data.length);
      const { Dataresult, totalTracteurs } = transformDataByPuissance(data);
      setDataPuissance(Dataresult);
      setTotalTracteur(totalTracteurs);
      const { DataresultMarque, total_Tractors } = transformDataByMarque(data);
      setdataMarque(DataresultMarque);
      setTotalTractors(total_Tractors);

        const { labels, data: monthlyData } = countFormationsByMonth(data);
        setChartData({
          labels,
          data: monthlyData,
        });
      const barChartData = countProductionsByCategory(data);
      setBarData(barChartData);
      const { tauxParPuissance, moyenneConformite } = Taux_conformite_par_tracteurs(data);
      settrackConf(tauxParPuissance); // Mettre à jour les données de conformité par puissance
      setconforme(moyenneConformite);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData3 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotalPanne(data.length);
      setcloture(calculerDossierCloture(data));
      setnoncloture(calculernonDossierCloture(data));
      
        const { labels, data: monthlyData } = countFormationsByMonth(data);
        setChartDataPannes({
          labels,
          data: monthlyData,
        });
      const barChartData = countProductionsByCategory(data);
      setBarData(barChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchData4 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      const ChartData = TrackData(data);
      settrackMark(ChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }; 
  const fetchData7 = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        
        const articlesParCategorie = regrouperArticlesParCategorie(data);
        
        const articlesParCategorieTrie = articlesParCategorie.sort((a, b) => b.count - a.count);
        
        setArticlesParCategorie(articlesParCategorieTrie);
    } catch (error) {
        console.error('Error fetching and processing data:', error);
    }
};

  async function fetchData66() {
    try {
      const responseProd = await fetch(prodIndust);
      if (!responseProd.ok) throw new Error('Failed to fetch data from panne');
      const dataProd = await responseProd.json();
      
      const responseTractor = await fetch(tractor);
      if (!responseTractor.ok) throw new Error('Failed to fetch data from tractor');
      const dataTractor = await responseTractor.json();
      
      const tractorMarkCounts = dataProd.reduce((acc, panneItem) => {
        const matchingTractor = dataTractor.find(tractorItem => tractorItem.id === panneItem.Numéro_chassis);
  
        if (matchingTractor) {
          const { tractorMark } = matchingTractor;
          if (!acc[tractorMark]) {
            acc[tractorMark] = 0;
          }
          acc[tractorMark]++;
        }
  
        return acc;
      }, {});
      
      // Convert to array, sort in descending order, and calculate total count
      const barChartData = Object.keys(tractorMarkCounts).map((tractorMark) => ({
        tractorMark,
        NombreParMarque: tractorMarkCounts[tractorMark],
      })).sort((a, b) => b.NombreParMarque - a.NombreParMarque);
  
      // Calculer le nombre total de tracteurs
      const totalTractors = Object.values(tractorMarkCounts).reduce((sum, count) => sum + count, 0);
  
      // Mettre à jour l'état avec les nouvelles données et le total
      setBarData2(barChartData);
  
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  }
  
  
  
  const fetchData6 = async (urlPanne) => {
    try {
      const response = await fetch(urlPanne);
      if (!response.ok) throw new Error('Failed to fetch data from panne');
      const dataPanne = await response.json();
  
      const tracteursParNomAppareil = regrouperTracteursParNomAppareil(dataPanne);
  
      const tracteursParNomAppareilData = Object.keys(tracteursParNomAppareil)
        .map(nomAppareil => ({
          name: nomAppareil, 
          count: tracteursParNomAppareil[nomAppareil].count,
          label: `${tracteursParNomAppareil[nomAppareil].count}`, 
        }))
        .sort((a, b) => b.count - a.count);

      setTracteurPuissance(tracteursParNomAppareilData);
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  };
  async function fetchData77() {
    try {
      const responseProdIndust = await fetch(prodIndust);
      if (!responseProdIndust.ok) throw new Error('Failed to fetch data from prodIndust');
      const dataProdIndust = await responseProdIndust.json();
  
      const responseTractor = await fetch(tractor);
      if (!responseTractor.ok) throw new Error('Failed to fetch data from tractor');
      const dataTractor = await responseTractor.json();
  
      const tracteursGroupesParMarque = dataProdIndust.reduce((acc, prodItem) => {
        const matchingTractor = dataTractor.find(tractorItem => tractorItem.id === prodItem.Numéro_chassis);
        if (matchingTractor) {
          const { tractorMark } = matchingTractor;
          if (!acc[tractorMark]) {
            acc[tractorMark] = {
              tractorMark,
              items: [],
            };
          }
          acc[tractorMark].items.push(prodItem);
        }
        return acc;
      }, {});
  
      const tauxParMarque = Object.keys(tracteursGroupesParMarque).map(tractorMark => {
        const groupe = tracteursGroupesParMarque[tractorMark];
        const taux = calculerTauxConformite2(groupe.items);
        return {
          name: tractorMark,
          tauxConformite: taux,
          tauxConformiteLabel: `${taux}%`,
        };
      });
  
      const tauxParMarqueTries = tauxParMarque.sort((a, b) => b.tauxConformite - a.tauxConformite);
  
      // Calculer la moyenne du tauxConformite
      const totalTaux = tauxParMarqueTries.reduce((sum, marque) => sum + marque.tauxConformite, 0);
      const moyenneTauxConformite = tauxParMarqueTries.length > 0 ? Math.trunc((totalTaux / tauxParMarqueTries.length)) : 0;
  
      // Mettre à jour l'état
      settrackConf2(Taux_conformite_par_marques(dataProdIndust));
      setAvgTauxConformite(moyenneTauxConformite); // Stocker la moyenne
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  }

  async function fetchData5() {
    try {
        const responsePanne = await fetch(panne);
        const dataPanne = await responsePanne.json();
        const responseTractors = await fetch(tractor);
        const dataTractors = await responseTractors.json();

        const tractorMap = new Map();
        dataTractors.forEach(tractor => {
            tractorMap.set(tractor.id, tractor.tractorMark);
        });
        const panneCountByMark = {};
        dataPanne.forEach(panne => {
            const tractorMark = tractorMap.get(panne['Numéro de châssis']);
            if (tractorMark) {
                if (!panneCountByMark[tractorMark]) {
                    panneCountByMark[tractorMark] = 0;
                }
                panneCountByMark[tractorMark]++;
            }
        });
        const chartDataPannesByMark = Object.keys(panneCountByMark).map(mark => ({
            mark,
            pannes: panneCountByMark[mark]
        }));
        return { chartDataPannesByMark };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { chartDataPannesByMark: [] }; 
    }
}
  const handleAnneeChange = (event, value) => {
    setSelectedAnnee(value?.label || null);
  };
  const isXs = useMediaQuery('(max-width:600px)');
  return (
    <Box sx={{ display: 'flex' }}>
    <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
      <Toolbar />
      <Box display="flex" justifyContent="space-between" mb={3}>
        <CalendarButton />
        <ButtonComponent />
      </Box>
        <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Production et qualité', 'Maintenance et pannes'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
          <TabPanel value="1">
            <Box display="flex" alignItems="center" mb={2}>
                <Autocomplete
                  options={vagueOptions}
                  onChange={handleVagueChange}
                  renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
                  sx={{ width: 200, ml: 2 }} />
                
              </Box>
              {selectedVague === "" && (
              
              <>
                  <div style={{ textAlign: 'center', marginBottom: isXs ? '15px': ''}}>
                  <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem'}}>Données du mois de</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
              <Grid container spacing={3}>
               <Grid item xs={12} md={6}>
                  <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem'}}>Equipement motorisés</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem',}}>({total_tracteur})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '20px' }} />}

                    {dataPuissance&& dataPuissance.length > 0 ? (
                      <>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={dataPuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="ProductionParArticle" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="ProductionParArticle"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                          <FlecheComponent currentMonthValue={total_tracteur} previousMonthValue={totalTractors} />
                        </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                   <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité des tracteurs</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${conformite}%`})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {trackConf && trackConf.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={trackConf}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip />
                          <Bar dataKey="tauxConformite" fill="#02834A" radius={[5, 5, 5, 5]}>
                            <LabelList
                              dataKey="tauxConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                      <FlecheComponent currentMonthValue={conformite} previousMonthValue={'100%'}/>
                     </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Modal
              open={showMore}
              onClose={handleShowMore}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft:20 }}
            >
              <Box 
                sx={{ 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 2, 
                  borderRadius: 2, 
                  outline: 'none',
                  width:900,
                  height:500,
                  overflow:'auto'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Equipements non motorisés</span>
                            <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: '2.5rem', }}>({barData.totalAUTRES})</span>
                          </div>
                {barData.AUTRES && barData.AUTRES.length > 0 ? (
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                      layout="horizontal"
                      data={barData.AUTRES}
                      width={500}
                      height={300}
                      margin={{ right: 20, left: 10, bottom: 50 }}
                    >
                      <YAxis type="number" axisLine={false} tick={false} />
                      <XAxis type="category" dataKey="name" axisLine={false} tickMargin={25} tickLine={false} interval={2} tick={{ angle: -15, fontSize: 14, fontWeight:'bold' }} />
                      <Tooltip />
                      <Bar dataKey="ProductionParArticle" fill="#02834A" radius={[5, 5, 5, 5]}>
                        <LabelList
                          dataKey="ProductionParArticle"
                          position="top"
                          fill="#02834A"
                          offset={5} 
                          fontSize={14} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                )}
              </Box>
               </Modal>        
                <Grid item xs={12} md={6} mt={3}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: 1000 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: {xs:'0.5rem', sm:'1.5rem', md: '1.5rem' }}}>Equipements non motorisés par catégorie</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: {xs:'0.5rem', sm:'1.5rem', md: '2.5rem' } }}>({barData.totalAUTRES})</span>
                    </div>
                    {articlesParCategorie && articlesParCategorie.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={articlesParCategorie}
                          margin={isXs ? {} : { top: 5, right: 30, left: 20, bottom: 5 }} 
                        >
                          <XAxis type="category" dataKey="name" tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <YAxis tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <Tooltip />
                          <Bar dataKey="count" fill="#02834A" radius={[5, 5, 5, 5]}>
                            <LabelList dataKey="count" position="insideEnd" fill="#fff" fontSize={18}/>
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                 {!isXs && <Box textAlign="center" mt={2}>
                    <Typography 
                      component="span" 
                      onClick={handleShowMore} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: 'success.main', 
                        textDecoration: 'underline',
                        fontFamily:'Times New Roman',
                        fontSize:'1.2rem'
                      }}
                    >
                      {showMore ? 'Voir moins...' : 'Voir plus...'}
                    </Typography>
                  </Box>}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={3}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: 1000 }, height: { xs: 400, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                    <Typography sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center', fontSize: '1.5rem' }}>
                      Tracteurs par marque
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={trackMark}
                        layout="vertical"
                        margin={isXs ? {top: 5, right: 10, left: 30, bottom: 50} : { top: 5, right: 20, left: 100, bottom: 50 }} 
                        >
                        <XAxis type="number" axisLine={false} tick={false} />
                        <YAxis type="category" dataKey="tractorMark" axisLine={false}
                          tickMargin={20}
                          tickLine={false}
                          interval={2}
                          tick={{ fontSize: 14, fontFamily: 'Times New Roman', fontWeight:'bold' }} />
                        <Tooltip />
                        <Bar dataKey="NombreParMarque" fill="#02834A" radius={[5, 5, 5, 5]}>
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#02834A" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid></>
 )}
          {selectedVague === "Marques" && (
           <>
                 <div style={{ textAlign: 'center'}}>
                  <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Données du mois de</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem': '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <Paper
                elevation={3}
                sx={{
                  width: { xs: '100%', sm: '90%', md: 'auto' },
                  maxWidth: { xs: 350, sm: 500, md: 1000 },
                  height: { xs: 270, sm: 300, md: 300 },
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Production par marque</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({totalTractors})</span>
                    </div>
                  {dataMarque.length > 0 ? (
                     <>
                    <ResponsiveContainer width='100%' height={200}>
                      <BarChart
                        layout="vertical"
                        data={dataMarque}
                        margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                        >
                        <XAxis type="number" axisLine={false} tick={false} />
                        <YAxis
                          type="category"
                          dataKey="name"
                          axisLine={false}
                          tickMargin={isXs ? 10 :20}
                          width={isXs ? 120 : 130}
                          tickLine={false}
                          tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                           />
                        <Tooltip />
                        <Bar dataKey="ProductionParArticle" fill="#02834A" radius={[5, 5, 5, 5]}>
                        <LabelList
                              dataKey="ProductionParArticle"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                    <FlecheComponent currentMonthValue={totalTractors} previousMonthValue={900} />
                      </div>}
                     </>
                  ) : (
                    <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  width: { xs: '100%', sm: '90%', md: 'auto' },
                  maxWidth: { xs: 350, sm: 500, md: 600 },
                  height: { xs: 270, sm: 300, md: 300 },
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >                
              <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par marque</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({avgTauxConformite}%)</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}

                  {trackConf2 && trackConf2.length > 0 ? (
                    <>
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart
                        layout="vertical"
                        data={trackConf2}
                        margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                      >
                        <XAxis type="number" axisLine={false} tick={false} />
                        <YAxis
                          type="category"
                          dataKey="name"
                          axisLine={false}
                          tickMargin={isXs ? 10 :20}
                          width={isXs ? 120 : 130}
                          tickLine={false}
                          tick={{ fontSize: 14, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                           />
                        <Tooltip />
                        <Bar dataKey="tauxConformite" fill="#02834A" radius={[5, 5, 5, 5]}>
                            <LabelList
                              dataKey="tauxConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                    <FlecheComponent currentMonthValue={avgTauxConformite} previousMonthValue={'100%'} />
                     </div>}
                     </>
                  ) : (
                    <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                  )}
                </Paper>
              </Grid>
              </Grid>
              <Paper
                elevation={3} 
                sx={{
                  width: { xs: '100%', sm: '90%', md: 'auto' },
                  maxWidth: { xs: 350, sm: 500, md: 1000 },
                  height: { xs: 380, sm: 400, md: 400 },
                  marginTop:3,
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
>                  <Typography sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center', fontSize: isXs ? '1rem' :'1.5rem' }}>
                    Tracteurs disponibles par marque
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={trackMark}
                      layout="vertical"
                      margin={{ top: 5, right: 20, left: 100, bottom:50 }}>
                      <XAxis type="number" axisLine={false} tick={false} />
                      <YAxis type="category" dataKey="tractorMark" axisLine={false}
                        tickMargin={20}
                        tickLine={false}
                        interval={2}
                        tick={{ fontSize: 14, fontFamily: 'Times New Roman' }} />
                      <Tooltip />
                      <Bar dataKey="NombreParMarque" fill="#02834A" radius={[5, 5, 5, 5]}>
                        </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#02834A" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid>
                </>
          )}

          {selectedVague === "Puissance motrice" && (
            <>
                  <div style={{ textAlign: 'center'}}>
                  <span style={{ fontWeight: 'bold', fontSize:isXs ? '1rem' : '1.5rem' }}>Données du mois de</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  >   
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Equipements motorisés</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' :'2.5rem', }}>({total_tracteur})</span>
                    </div>
                    {dataPuissance && dataPuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={dataPuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="ProductionParArticle" fill="#02834A" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="ProductionParArticle"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                         <FlecheComponent currentMonthValue={total_tracteur} previousMonthValue={totalTractors} />
                       </div>}
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  >                    
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité des tracteurs</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${conformite}%`})</span>
                    </div>
                    {trackConf && trackConf.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={trackConf}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 14, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip />
                          <Bar dataKey="tauxConformite" fill="#02834A" radius={[5, 5, 5, 5]}>
                            <LabelList
                              dataKey="tauxConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                      <FlecheComponent currentMonthValue={conformite} previousMonthValue={'100%'}/>
                      </div>}
                       </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Modal
              open={showMore}
              onClose={handleShowMore}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft:20 }}
            >
              <Box 
                sx={{ 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 2, 
                  borderRadius: 2, 
                  outline: 'none',
                  width:900,
                  height:500,
                  overflow:'auto'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Equipements non motorisés</span>
                            <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: '2.5rem', }}>({barData.totalAUTRES})</span>
                          </div>
                {barData.AUTRES && barData.AUTRES.length > 0 ? (
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                      layout="horizontal"
                      data={barData.AUTRES}
                      width={500}
                      height={300}
                      margin={{ right: 20, left: 10, bottom: 50 }}
                    >
                      <YAxis type="number" axisLine={false} tick={false} />
                      <XAxis type="category" dataKey="name" axisLine={false} tickMargin={25} tickLine={false} interval={2} tick={{ angle: -15, fontSize: 14 }} />
                      <Tooltip />
                      <Bar dataKey="ProductionParArticle" fill="#02834A" radius={[5, 5, 5, 5]}>
                        <LabelList
                          dataKey="ProductionParArticle"
                          position="top"
                          fill="#02834A"
                          fontSize={14} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                )}
              </Box>
               </Modal>        
                <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: { xs: '100%', sm: '90%', md: 'auto' },
                    maxWidth: { xs: 350, sm: 500, md: 1000 },
                    height: { xs: 380, sm: 400, md: 400 },
                    padding: 2,
                    borderRadius: 3,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >                   
                 <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Equipements non motorisés par catégorie</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({barData.totalAUTRES})</span>
                    </div>
                    {articlesParCategorie && articlesParCategorie.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={articlesParCategorie}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis type="category" dataKey="name" tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <YAxis tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <Tooltip />
                          <Bar dataKey="count" fill="#02834A" radius={[5, 5, 5, 5]}>
                            <LabelList dataKey="count" position="insideEnd" fill="#fff" fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                {!isXs && <Box textAlign="center" mt={2}>
                    <Typography 
                      component="span" 
                      onClick={handleShowMore} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: 'success.main', 
                        textDecoration: 'underline',
                        fontFamily:'Times New Roman',
                        fontSize:'1.2rem'
                      }}
                    >
                      {showMore ? 'Voir moins...' : 'Voir plus...'}
                    </Typography>
                  </Box>}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#02834A" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid>
                </>
          )}
          
          </TabPanel>
       <TabPanel value="2">
       <Autocomplete
        options={vagueOptions}
        onChange={handleVagueChange}
        renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
        sx={{ width: 200, ml: 2 }} 
      />
      {(selectedVague === "" || selectedVague === "Marques") && (
        <Grid item xs={12} md={6} mt={1} lg={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: { xs: '100%', sm: '90%', md: 'auto' },
                    maxWidth: { xs: 350, sm: 500, md: 1000 },
                    height: { xs: 380, sm: 400, md: 400 },
                    padding: 2,
                    borderRadius: 3,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >          
                <Typography   sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
                  textAlign: 'center', 
                  mb: { xs: 2, sm: 3 },
                  color: '#333'
                }}>
             Panne par marque
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={chartDataPannesByMark}
              layout="vertical"
              margin={isXs? {left:40}: { top: 5, right: 30, left: 90, bottom: 5 }}
            >
              <XAxis type="number" axisLine={false} tick={false} />
              <YAxis type="category" dataKey="mark" axisLine={false} tickMargin={20} tickLine={false} tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
              />
              <Tooltip />
              <Bar dataKey="pannes" fill="#02834A" radius={[5, 5, 5, 5]} />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
    </Grid>
      )}
    {(selectedVague === "" | selectedVague === "Puissance motrice") &&(
    <Grid item xs={12} md={6} lg={12} mt={3}>
          <Paper
            elevation={3}
            sx={{
              width: { xs: '100%', sm: '90%', md: 'auto' },
              maxWidth: { xs: 350, sm: 500, md: 1000 },
              height: { xs: 380, sm: 400, md: 400 },
              padding: 2,
              borderRadius: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >      <Typography   
                  sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
                  textAlign: 'center', 
                  mb: { xs: 2, sm: 3 },
                  color: '#333'
                }}> Panne par puissance motrice
        </Typography>
        {tracteurPuissance && tracteurPuissance.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
            <BarChart
              layout="vertical"
              data={tracteurPuissance} 
              margin={isXs ? {}: { top: 10, right: 30, left: 50, bottom: 5 }}
            >
              <XAxis type="number" axisLine={false} tick={false} />
              <YAxis
                type="category"
                dataKey="name"
                axisLine={false}
                tickMargin={isXs ? 2: 15}
                tickLine={false}
                width={isXs ? 100 : 250}
                tick={{ fontSize: isXs ? 15: 18, fontFamily: 'Times New Roman', fontWeight:'bold' }}
              />
              <Tooltip />
              <Bar dataKey="count" fill="#02834A" radius={[5, 5, 5, 5]}>
                <LabelList
                  dataKey="label"
                  position="right"
                  fill="#000"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
        )}
      </Paper>
      
    </Grid>
    )}
            <Box mt={3}>
              <Autocomplete
                options={anneeOptions}
                getOptionLabel={(option) => option.label}
                onChange={handleAnneeChange}
                renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                sx={{ width: 150 }}
              />
            </Box>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      marginTop:'15px',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de pannes par mois</Typography>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                <LineChart width="100%" height={400} data={chartDataPannes.data.map((Pannes, index) => ({
                  month: chartDataPannes.labels[index], 
                  Pannes
                }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Pannes" stroke="#02834A" />
                </LineChart>
  </ResponsiveContainer>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Directeur'])(FormationComponent);