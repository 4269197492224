import React, { useState, useEffect } from 'react';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Box, Paper, CircularProgress } from '@mui/material';
import CustomCard from './CustomCard';
import { stastiqueVisite, visite } from '../api/auth_constant';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import TableComponent from './TableComponent';
import TypeChart from './TypeChart';

function VisiteComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [total_visites, settotal_visites] = useState(0);
  const [Autre, setAutre] = useState(0);
  const [Motoculteurs, setMotoculteurs] = useState(0);
  const [Tracteurs, setTracteurs] = useState(0);
  const [visitesData, setVisitesData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [typeChartData, setTypeChartData] = useState([]);


  useEffect(() => {
    fetchData(stastiqueVisite);
    fetchVisitesData(visite);
  }, []);

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      settotal_visites(data["total_visites"]);
      setAutre(data["total_equipements_supplementaires"]);
      setMotoculteurs(data["total_motoculteurs"]);
      setTracteurs(data["total_tracteurs"]);

      const formattedData = Object.entries(data.visites_par_marque).map(([label, value], index) => ({
        id: index,
        label: label,
        value: value,
      }));
      setTypeChartData(formattedData);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchVisitesData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Assurez-vous que les dates sont bien formatées ici, si nécessaire
      const sortedData = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      setVisitesData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const columns = [
    { id: 'Client', label: 'Client', align: 'right' },
    { id: 'Date', label: 'Date', align: 'right' },
    { id: 'Cause', label: 'Cause', align: 'left' },
    { id: 'Description', label: 'Description', align: 'right' },
    { id: 'Dossier clôturé', label: 'Dossier clôturé', align: 'right' },
    { id: 'Intervenant', label: 'Intervenant', align: 'right' },
    { id: 'Machine en panne', label: 'Machine en panne', align: 'right' },
    { id: 'Numéro de châssis', label: 'Numéro de châssis', align: 'right' },
    { id: 'Genre de visite', label: 'Genre de visite', align: 'right' },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomCard title="Total des visites" value={`${total_visites}`} color="#ffff" />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard title="Tracteurs" value={`${Tracteurs}`} color="#ffff" />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard title="Motoculteurs" value={`${Motoculteurs}`} color="#ffff" />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={6}>
          <Typography  variant="h6" 
                      sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}>
            Visites par puissance motrice
          </Typography>
          <Paper 
            elevation={16} 
            sx={{ 
              padding: 1, 
              width: { xs: '100%', sm: '90%', md: '100%' },
              maxWidth: { xs: 300, sm: 500, md: 400 },
              height: { xs: 250, sm: 300, md: 400 },
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
          }}
          >
            <TypeChart data={typeChartData} />
          </Paper>
        </Grid>
      </Grid>
      <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', // Prend 100% de la largeur
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '100%',   // 100% pour sm et au-delà
                  },
                }}
              >
                <TableComponent
                  data={visitesData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>
    </Box>
  );
};


export default RoleCheck(['Mecanicien'])(VisiteComponent);