import * as React from 'react';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/system';
import '../App.css'

export default function CustomCard({ title, value, color, svalue,icon, progress }) {
  const currentDate = new Date();
   const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });  
   
   return (
    <Card 
    	variant="solid" 
      color={color} 
      sx={{ 
        height: '170px', 
        border: '1px solid #02834A', 
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
        },
        borderRadius: '20px',
        width: '230px',
      }}
    >
 	<Box display="flex" justifyContent="flex-start" alignItems="center">
        {/* Encadrer l'icône dans un cercle stylisé */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            width: '35px', 
            height: '35px', 
            borderRadius: '50%', 
            backgroundColor: 'rgba(255, 255, 255, 0)', // Cercle transparent avec une légère couleur
            border: '2px solid #F6CA11', // Bordure subtile jaune
            marginRight: '8px', // Un léger espace entre l'icône et le titre
          }}
        > 
          <Box sx={{ color: '#F6CA11', fontSize: '1rem' }}> 
            {icon}
          </Box>
        </Box>
	      <Typography 
          level="body-md" 
          sx={{ fontSize: '1.2rem', fontFamily: 'Times New Roman', color: '#02834A' }}
        >
          {title}
        </Typography>
      </Box>      
      <Typography 
        level="body-xs" 
        color="text.secondary" 
        sx={{ fontSize: '1rem', fontFamily: 'Times New Roman' }}
      >
        {formattedDate}
      </Typography>


      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography 
          level="body-sm" 
          sx={{ 
            fontWeight: 'bold', 
            fontSize: '0.8rem',
            marginRight: '8px' // Espace entre le texte et la valeur
          }}
        >
          {/* Texte ou élément que vous voulez ajouter */}
          {"Valeur actuelle:"}
        </Typography>
        <Typography 
          level="h2" 
          sx={{ 
            color: '#02834A', 
            fontWeight: 'bold', 
            fontSize: '2.5rem', 
          }}
        >
          {value}
        </Typography>
      </Box>

    </Card>
  );
}
