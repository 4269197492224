// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tooltip{
  position: absolute;
  border-radius: 1rem;
  padding: 10px;
  background-color: gray;
  visibility: hidden;
}

.transparent-card {
  background-color: rgba(255, 255, 255, 0.5) !important;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,qDAAqD;EACrD,8CAAsC;UAAtC,sCAAsC;AACxC","sourcesContent":["#tooltip{\n  position: absolute;\n  border-radius: 1rem;\n  padding: 10px;\n  background-color: gray;\n  visibility: hidden;\n}\n\n.transparent-card {\n  background-color: rgba(255, 255, 255, 0.5) !important;\n  backdrop-filter: blur(10px) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
