import React, { useState }  from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Login';
import Signup from './Components/Signup';
import DashboardInformaticien from './Components/Dashboards/DashboardInformaticien';
import DashboardMecanicien from './Components/Dashboards/DashboardMecanicien';
import DashboardCommercial from './Components/Dashboards/DashboardCommercial';
import DashboardDirecteur from './Components/Dashboards/DashboardDirecteur';
import DashboardFinancier from './Components/Dashboards/DashboardFinancier';
import CustomCard from './Components/CustomCard';
import FormationComponent from './Components/FormationComponent';
import OperationAgricolComponent from './Components/OperationAgricolComponent';
import VisiteComponent from './Components/VisiteComponent';
import ProductionAgricoleComponent from './Components/ProductionAgricoleComponent';
import ProductionIndustrielleComponent from './Components/ProductionIndustrielleComponent';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

  const [user, setUser] = useState(null);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login onLogin={handleLogin} />} />
      <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard_director"  element={<DashboardDirecteur />} />
        <Route path="/dashboard_mechanic"  element={<DashboardMecanicien />} />
        <Route path="/dashboard_sales"  element={<DashboardCommercial />} />
        <Route path="/dashboard_it"  element={<DashboardInformaticien />}/>
        <Route path="/dashboard_fin"  element={<DashboardFinancier />} />
        <Route path="/intervention"  element={<CustomCard />} />
        <Route path="/retex"  element={<CustomCard />} />
        <Route path="/training"  element={<FormationComponent />} />
        <Route path="/operation_agricole"  element={<OperationAgricolComponent />} />
        <Route path="/production_agricole"  element={<ProductionAgricoleComponent />} />
        <Route path="/production"  element={<ProductionIndustrielleComponent />} />
        <Route path="/visite"  element={<VisiteComponent />} />



      </Routes>
    </Router>
  );
}

export default App;
