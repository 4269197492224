import React, { useState, useEffect } from 'react';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, Typography, Container, Grid, Box, Paper, Autocomplete, TextField } from '@mui/material';
import CustomCard from './CustomCard';
import { statistiqueUrl } from '../api/auth_constant';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import dayjs from 'dayjs';
import MapChart from "../Components/Dashboards/DashBoardDriversUtils/MapChart";
import { BarChart } from '@mui/x-charts/BarChart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { heuremoteurUrl } from '../api/auth_constant';

const OperationAgricolComponent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [Total, setTotal] = useState(0);
  const [Distance, setDistance] = useState(0);
  const [Heure_moteur, setHeure_moteur] = useState(0);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  const [heuremoteurData, setheuremoteurData] = useState(null);

  useEffect(() => {
    fetchData(statistiqueUrl);
  }, []);



  const fetchData = async (url) => {
    try {
      const cachedData = localStorage.getItem('statistiqueData');
      if (cachedData) {
        const data = JSON.parse(cachedData);
        setTotal(data["Total"]);
        setDistance(data["Distance"]);
        setHeure_moteur(data["Heure_moteur"]);
        return;
      }
  
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTotal(data["Total"]);
      setDistance(data["Distance"]);
      setHeure_moteur(data["Heure_moteur"]);
      localStorage.setItem('statistiqueData', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    if (dateDebut && dateFin) {
      fetchheuremoteurData(dateDebut, dateDebut);
    }
  }, [dateDebut, dateFin]);

  const fetchheuremoteurData = async () => {
    try {
      const cacheKey = `heuremoteurData_${dateDebut}_${dateFin}`;
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        setheuremoteurData(JSON.parse(cachedData));
        return;
      }
  
      const requestBody = {
        date_debut: dateDebut, 
        date_fin: dateFin
      };
  
      const response = await fetch(heuremoteurUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setheuremoteurData(data);
      localStorage.setItem(cacheKey, JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching agriculture data:', error);
    }
  };
  
  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  console.log("data heuremoteur", heuremoteurData);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        
        <Grid container display="flex" alignItems="center" spacing={1} mt={3}>
          {/* Date pickers */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* Date de début */}
                <Box sx={{ flex: 1, minWidth: '150px', mb: { xs: 2, md: 0 } }}> 
                  <DatePicker
                    label="Date de début"
                    value={dateDebut}
                    onChange={(newValue) => setDateDebut(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
                {/* Date de fin */}
                <Box sx={{ flex: 1, minWidth: '150px', ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                  <DatePicker
                    label="Date de fin"
                    value={dateFin}
                    onChange={(newValue) => setDateFin(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
        
        {/* Chart and Map */}
        <Box>
          {/* Bar chart */}
          <Paper 
            elevation={2} 
            sx={{ mt: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 500 }}
          >
            {heuremoteurData && (
              <BarChart
                series={[{ data: heuremoteurData.Heure_moteur, label: 'Heures Moteur', id: 'heure' }]}
                xAxis={[{ 
                  scaleType: 'band', 
                  data: heuremoteurData.Date, 
                  colorMap: { 
                    type: 'ordinal', 
                    colors: ['#964B00', '#dda827', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'] 
                  } 
                }]}
                margin={{ left: 80, right: 80, top: 80, bottom: 80 }}
              />
            )}
          </Paper>
  
          {/* Map chart */}
          <Paper 
            elevation={2} 
            sx={{ 
              display: 'flex', justifyContent: 'center', flexDirection: 'column', 
              height: 'auto', width: '100%', maxWidth: 430, position: 'relative', mt: 5 
            }}
          >
            <MapChart />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
  
};

export default OperationAgricolComponent;