import { Fragment } from 'react';
import { BarChart, axisClasses } from '@mui/x-charts';
import { useTheme } from '@mui/material';
import { NumberFormater } from './NumberFormater';
export default function BarChartRender(props)
{
  const theme = useTheme();
  let {datas}=props
    return (
        <>
        <Fragment>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
            {datas.length!==0? <BarChart
          dataset={datas}
          margin={{
            top: 70,
            right: 30,
            left: 70,
            bottom: 50,
          }}
          xAxis={
           [ {
              id:'xAxisBottom',
              label:"Ville",
              scaleType: 'band',
              dataKey: '_id',
              tickFontSize:8,
              tickLabelPlacement:'tick',
              tickPlacement:'start',
              position:'bottom'
            },
          ]
          }
          yAxis={[
            {
              id:"yAxisLeft",
              dataKey:'nombre_clients',
              label: 'Nombre Client',
              labelStyle: {
                ...theme.typography.caption,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.caption,
              max: Math.max(...props.datas.map((item,i)=>item['nombre_clients'])),
              scaleType:'linear',
              valueFormatter:(value)=>NumberFormater(value)
            }
            
          ]}
          leftAxis={'yAxisLeft'}
          bottomAxis={'xAxisBottom'}
          series={[
            {
              yAxisKey:'yAxisLeft',
              dataKey: 'nombre_clients',
              showMark: false ,
              color: "#02834A",
              label:'Nombre de client',
            }
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.primary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
            [`& .${axisClasses.right} .${axisClasses.label}`]: {
              transform: 'translateX(25px)',
            }
          }}
          grid={{horizontal:true}}
        />:""}
      </div>
      
    </Fragment>
        </>
    )

}